import { useMutation } from "@apollo/client";
import { getAllRoles } from "@shared/utils/getAllRoles";

import {
  CREATE_OEM_SUPPORT_ACCOUNT_MUTATION,
  CURRENT_USER_QUERY,
  LIST_OWN_OEM_SUPPORT_ACCOUNTS_QUERY,
  REMOVE_USER_BOARD,
  UPDATE_OEM_SUPPORT_ACCOUNT_MUTATION,
  UPDATE_PERSONAL_SETTINGS,
  ADD_USER_BOARD,
  ASSIGN_MULTIPLE_SKILLS_USER_MUTATION,
  LINK_CALENDAR_SYNC_ACCOUNT_MUTATION,
  UNLINK_CALENDAR_SYNC_ACCOUNT_MUTATION,
  UPDATE_USER_EMAIL_SIGNATURE_MUTATION,
  CUSTOMER_PORTAL_SIGNUP_MUTATION,
  APPROVE_INVITE_MUTATION,
  DECLINE_INVITE_MUTATION,
  REMOVE_FIRST_SIGN_IN_REDIRECT_URL_MUTATION,
} from "~/api";

const ALL_ROLES = getAllRoles();

export const useUpdateOemSupportAccount = () => {
  const [updateOemSupportAccount, { loading, error }] = useMutation(
    UPDATE_OEM_SUPPORT_ACCOUNT_MUTATION,
  );

  return {
    loading,
    error,
    updateOemSupportAccount: async (input) =>
      updateOemSupportAccount({
        variables: { input },
        update(cache) {
          const { currentUser } = cache.readQuery({
            query: CURRENT_USER_QUERY,
          });
          const { listOwnOemSupportAccounts } = cache.readQuery({
            query: LIST_OWN_OEM_SUPPORT_ACCOUNTS_QUERY,
          });

          if (
            currentUser._id !== input._id &&
            input.role === ALL_ROLES.OWNER.VALUE &&
            currentUser.role === ALL_ROLES.OWNER.VALUE
          ) {
            cache.writeQuery({
              query: CURRENT_USER_QUERY,
              data: {
                currentUser: { ...currentUser, role: ALL_ROLES.OEM.VALUE },
              },
            });
            const newData = listOwnOemSupportAccounts.map((oemAccount) =>
              oemAccount._id === currentUser._id
                ? { ...oemAccount, role: ALL_ROLES.OEM.VALUE }
                : oemAccount,
            );

            cache.writeQuery({
              query: LIST_OWN_OEM_SUPPORT_ACCOUNTS_QUERY,
              data: {
                listOwnOemSupportAccounts: newData,
              },
            });
          }
        },
      }),
  };
};

export const useCreateOemSupportAccount = () => {
  const [create, { loading, error }] = useMutation(
    CREATE_OEM_SUPPORT_ACCOUNT_MUTATION,
  );
  return {
    loading,
    error,
    createOemSupportAccount: async (input) =>
      create({
        variables: {
          input,
        },
        update(cache, { data: { createOemSupportAccount: newUser } }) {
          const { listOwnOemSupportAccounts } = cache.readQuery({
            query: LIST_OWN_OEM_SUPPORT_ACCOUNTS_QUERY,
          });

          const newData = [...listOwnOemSupportAccounts, newUser];

          cache.writeQuery({
            query: LIST_OWN_OEM_SUPPORT_ACCOUNTS_QUERY,
            data: {
              listOwnOemSupportAccounts: newData,
            },
          });
        },
      }),
  };
};

export const useCustomerPortalSignup = () => {
  const [signup, { loading, error }] = useMutation(
    CUSTOMER_PORTAL_SIGNUP_MUTATION,
  );
  return {
    loading,
    error,
    customerPortalSignUp: async (input) =>
      signup({
        variables: {
          input,
        },
      }),
  };
};

export const useApproveInvite = () => {
  const [approveInvite, { loading, error }] = useMutation(
    APPROVE_INVITE_MUTATION,
  );
  return {
    approvingInvite: loading,
    error,
    approveInvite: async (token) =>
      approveInvite({
        variables: {
          token,
        },
      }),
  };
};

export const useDeclineInvite = () => {
  const [declineInvite, { loading, error }] = useMutation(
    DECLINE_INVITE_MUTATION,
  );
  return {
    decliningInvite: loading,
    error,
    declineInvite: async (token) =>
      declineInvite({
        variables: {
          token,
        },
      }),
  };
};

export const useUpdatePersonalNotificationSettings = () => {
  const [update, { loading, error }] = useMutation(UPDATE_PERSONAL_SETTINGS);

  return {
    loading,
    error,
    updatePersonalNotificationSettings: async (emailNotificationSettings) =>
      update({
        variables: {
          emailNotificationSettings,
        },
      }),
  };
};

export const useUpdatePersonalGeneralSettings = () => {
  const [update, { loading, error }] = useMutation(UPDATE_PERSONAL_SETTINGS);

  return {
    loading,
    error,
    updatePersonalGeneralSettings: async (personalSettings) =>
      update({
        variables: {
          personalSettings,
        },
      }),
  };
};

export const useAddBoard = () => {
  const [update, { loading, error }] = useMutation(ADD_USER_BOARD);

  return {
    loading,
    error,
    addBoard: async (board) =>
      update({
        variables: { board },
        update(cache, { data: { addUserBoard: ref } }) {
          const { currentUser } =
            cache.readQuery({
              query: CURRENT_USER_QUERY,
            }) || {};

          if (currentUser) {
            cache.writeQuery({
              query: CURRENT_USER_QUERY,
              data: {
                currentUser: { ...currentUser, boards: [...ref.boards] },
              },
            });
          }
        },
      }),
  };
};

export const useRemoveBoard = () => {
  const [remove, { loading, error }] = useMutation(REMOVE_USER_BOARD);

  return {
    loading,
    error,
    removeBoard: async (board) =>
      remove({
        variables: { board },
        update(cache, { data: { removeUserBoard: ref } }) {
          const { currentUser } =
            cache.readQuery({
              query: CURRENT_USER_QUERY,
            }) || {};

          if (currentUser) {
            cache.writeQuery({
              query: CURRENT_USER_QUERY,
              data: {
                currentUser: { ...currentUser, boards: [...ref.boards] },
              },
            });
          }
        },
      }),
  };
};

export const useAssignSkillsUser = () => {
  const [assign, { loading, error }] = useMutation(
    ASSIGN_MULTIPLE_SKILLS_USER_MUTATION,
  );

  return {
    assignLoading: loading,
    error,
    assignSkills: async (input) =>
      assign({
        variables: { input },
        update(cache, { data: { assignMultipleSkillsToOemUser: ref } }) {
          const { listOwnOemSupportAccounts } = cache.readQuery({
            query: LIST_OWN_OEM_SUPPORT_ACCOUNTS_QUERY,
          });

          let newData = [...listOwnOemSupportAccounts];
          const index = newData.findIndex((user) => user._id === ref._id);

          if (index >= 0) {
            newData[index] = {
              ...newData[index],
              skills: ref.skills,
            };
          }

          cache.writeQuery({
            query: LIST_OWN_OEM_SUPPORT_ACCOUNTS_QUERY,
            data: {
              listOwnOemSupportAccounts: newData,
            },
          });
        },
      }),
  };
};

const updateCurrentUserCache = (cache, user, increment = false) => {
  const currentUserCache = cache.readQuery({
    query: CURRENT_USER_QUERY,
    variables: {},
  });
  // eslint-disable-next-line no-console
  if (!currentUserCache) return;

  const cloned = JSON.parse(JSON.stringify(currentUserCache.currentUser));

  cloned.calendarSyncAccountLinked = user.calendarSyncAccountLinked;
  cloned.calendarSyncAccountStatus = user.calendarSyncAccountStatus;
  cloned.linkedCalendarSyncEmailAddress = user.linkedCalendarSyncEmailAddress;

  cloned.oem.calendarSyncConfiguration.consumedConnectedAccounts += increment
    ? 1
    : -1;

  cache.writeQuery({
    query: CURRENT_USER_QUERY,
    variables: {},
    data: { currentUser: cloned },
  });
};

export const useLinkCalendarSyncAccount = () => {
  const [link, { loading, error }] = useMutation(
    LINK_CALENDAR_SYNC_ACCOUNT_MUTATION,
  );

  return {
    loading,
    error,
    linkCalendarSync: async (code) =>
      link({
        variables: {
          code,
        },
        update(cache, { data: { linkCalendarSyncAccount: ref } }) {
          try {
            updateCurrentUserCache(cache, ref, true);
          } catch (error) {
            //
          }
        },
      }),
  };
};

export const useUnlinkCalendarSyncAccount = () => {
  const [unlink, { loading, error }] = useMutation(
    UNLINK_CALENDAR_SYNC_ACCOUNT_MUTATION,
  );

  return {
    loading,
    error,
    unlinkCalendarSync: async () =>
      unlink({
        update(cache, { data: { unlinkCalendarSyncAccount: ref } }) {
          try {
            updateCurrentUserCache(cache, ref);
          } catch (error) {
            //
          }
        },
      }),
  };
};

export const useUpdateEmailSignature = () => {
  const [updateSignature, { loading, error }] = useMutation(
    UPDATE_USER_EMAIL_SIGNATURE_MUTATION,
  );

  return {
    loading,
    error,
    updateSignature: async ({ signature, autoAppendEmailSignature }) =>
      updateSignature({
        variables: { signature, autoAppendEmailSignature },
        update(cache, { data: { updateEmailSignature: ref } }) {
          const currentUserCache = cache.readQuery({
            query: CURRENT_USER_QUERY,
            variables: {},
          });
          // eslint-disable-next-line no-console
          if (!currentUserCache) return;

          const cloned = JSON.parse(
            JSON.stringify(currentUserCache.currentUser),
          );

          cloned.emailSignature = ref.emailSignature;
          cloned.autoAppendEmailSignature = ref.autoAppendEmailSignature;

          cache.writeQuery({
            query: CURRENT_USER_QUERY,
            variables: {},
            data: { currentUser: cloned },
          });
        },
      }),
  };
};

export const useRemoveFirstSignInRedirectUrl = () => {
  const [_removeFirstSignInRedirectUrl, { loading, error }] = useMutation(
    REMOVE_FIRST_SIGN_IN_REDIRECT_URL_MUTATION,
  );

  return {
    loading,
    error,
    removeFirstSignInRedirectUrl: async () =>
      _removeFirstSignInRedirectUrl({
        update(cache, { data: { removeFirstSignInRedirectUrl: ref } }) {
          const { currentUser } =
            cache.readQuery({
              query: CURRENT_USER_QUERY,
            }) || {};

          if (currentUser) {
            cache.writeQuery({
              query: CURRENT_USER_QUERY,
              data: {
                currentUser: ref,
              },
            });
          }
        },
      }),
  };
};
