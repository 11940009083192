import CalendarIcon from "@shared/svg/calendar-icon.svg?react";
import TextLeftIcon from "@shared/svg/text-left.svg?react";
import { Avatar, AVATAR_SIZES } from "@shared/ui/Avatar";
import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import DataTable, { DataTableColumnResizer } from "@shared/ui/DataTable";
import {
  NotificationCounter,
  COUNTER_SIZES,
  ExternalIcon,
  EXTERNAL_ICON_SIZES,
} from "@shared/ui/Indicators";
import NotFoundComponent from "@shared/ui/NotFound";
import moment from "moment-timezone";
import { User } from "phosphor-react";
import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { useRecoilValue } from "recoil";

import CUSTOM_FIELD_TYPES from "$/settings/enums/customAdditionalField/_type.json";
import PAID_FEATURES from "$/settings/paid-features.json";
import { unreadChannelMessagesAtom } from "~/atoms/_chat";
import SortingArrows from "~/components/_sortingArrows";
import { Loading } from "~/components/general";
import useAuth from "~/components/general/_use-auth";
import CreateTicketDrawer from "~/components/tickets/Create";
import TicketItemAssigneesList from "~/components/tickets/ticketItemAssigneesList";
import WorkOrderTypeIcons from "~/components/tickets/WorkOrderTypeIcons";
import {
  INITIATE_ADD_WORK_ORDER,
  MIXPANEL_EVENT_PROPERTIES,
  SUBMIT_NEW_WORK_ORDER,
} from "~/constants";
import { WORK_ORDER } from "~/constants/filters/scopes";
import {
  DATE_FORMAT_YYYY_MM_DD,
  TABLE_VIEW_COLUMN_MIN_WIDTH,
  TABLE_VIEW_COLUMN_SIZE_SUFFIX,
} from "~/constants/global";
import { WORK_ORDER_SORTING_KEY } from "~/constants/workOrders";
import useGetClosedStatus from "~/hooks/_useGetClosedStatus";
import {
  SidebarFacilityIcon,
  SidebarMachinesIcon,
  SidebarProceduresIcon,
  TableStatusIcon,
} from "~/icons/icons";
import { useListOwnOemCustomFields } from "~/services/customFields/_query";
import { getEnums } from "~/utils";
import { generateStatusBackgroundColor } from "~/utils/_colors";
import { generateCustomFieldColumns } from "~/utils/_dataTables";
import { registerMixpanelEvent } from "~/utils/_mixpanel";
import {
  useGetAppConfigRecoilValue,
  useIsGrowthLoopEnabled,
} from "~/utils/appFeatures";
import isPaidFeatureAvailable from "~/utils/isPaidFeatureAvailable";

const CustomFieldTypes = getEnums(CUSTOM_FIELD_TYPES, "reference");
const PaidFeatures = getEnums(PAID_FEATURES, "reference");

const TableView = ({
  tickets,
  totalCount,
  onScrollToBottom,
  isLoading,
  setSort,
  sort,
}) => {
  const history = useHistory();
  const { messages } = useIntl();
  const { user } = useAuth();
  const oem = user?.oem;
  const appConfig = useGetAppConfigRecoilValue();
  const { closedStatus } = useGetClosedStatus();
  const isGrowthLoopEnabled = useIsGrowthLoopEnabled();

  const localStorageKey = `${WORK_ORDER}${TABLE_VIEW_COLUMN_SIZE_SUFFIX}`;
  const sizeConfigs = JSON.parse(localStorage.getItem(localStorageKey) || "{}");

  const { customAdditionalFields, loading } = useListOwnOemCustomFields({
    type: CustomFieldTypes.tickets,
  });

  const handleCellClick = (id) => {
    history.push(`/app/work/work-orders/${id}`);
  };

  const predefinedColumns = React.useMemo(() => {
    return [
      {
        accessorKey: "ticketId",
        minSize: TABLE_VIEW_COLUMN_MIN_WIDTH,
        size: sizeConfigs["ticketId"],
        pin: "left",
        onClick: (id) => {
          handleCellClick(id);
        },
        Header: ({ header }) => (
          <div className="table-view-head-item">
            <BodyText
              size={BODY_TEXT_SIZES.X_SMALL}
              color="text-secondary"
              className="whitespace-nowrap truncate"
            >
              {messages?.tickets?.ticketID}
            </BodyText>

            <DataTableColumnResizer header={header} scope={WORK_ORDER} />
          </div>
        ),
        Cell: (info) => {
          const { ticketType, status, ticketChatChannels } = info.row.original;
          const unreadChannelMessages = useRecoilValue(
            unreadChannelMessagesAtom,
          );
          const notificationCounter =
            unreadChannelMessages?.channels[ticketChatChannels[0]] || 0;
          const styles = {
            backgroundColor: generateStatusBackgroundColor(ticketType?.color),
          };

          return (
            <div className="ticket-id">
              <span
                className={`ticket-icon btn-v2 secondary-icon-btn-v2 ${
                  status === closedStatus?._id ? "closed" : ""
                }`}
                style={status === closedStatus?._id ? {} : styles}
                onMouseEnter={() => {
                  ReactTooltip.rebuild();
                }}
                data-tip={
                  ticketType?.isInternal
                    ? ""
                    : messages?.workOrderTypes?.externalWorkOrderTooltip
                }
                data-for="global-tooltip"
                data-event="mouseenter"
                data-event-off="mouseleave"
              >
                <WorkOrderTypeIcons
                  icon={ticketType.icon}
                  strokeColor={ticketType.color}
                  size={12}
                />

                {ticketType.isInternal ? null : (
                  <ExternalIcon
                    size={EXTERNAL_ICON_SIZES.SMALL}
                    className="absolute -top-xs -right-xs"
                  />
                )}

                {parseInt(notificationCounter) > 0 && (
                  <NotificationCounter
                    size={COUNTER_SIZES.SMALL}
                    className="absolute -bottom-3xs -left-3xs border border-solid border-primary"
                  />
                )}
              </span>
              <BodyText size={BODY_TEXT_SIZES.X_SMALL}>
                {info.cell.getValue()}
              </BodyText>
            </div>
          );
        },
      },
      {
        accessorKey: "title",
        minSize: TABLE_VIEW_COLUMN_MIN_WIDTH,
        size: sizeConfigs["title"],
        pin: "left",
        onClick: (id) => {
          handleCellClick(id);
        },
        Header: ({ header }) => (
          <div className="table-view-head-item justify-between">
            <div className="flex flex-nowrap items-center overflow-hidden gap-xs">
              <span className="flex items-center">
                <TextLeftIcon size={16} className="text-secondary" />
              </span>
              <BodyText
                size={BODY_TEXT_SIZES.X_SMALL}
                color="text-secondary"
                className="whitespace-nowrap truncate"
              >
                {messages?.tickets?.workOrderName}
              </BodyText>
            </div>

            <SortingArrows
              identifier={WORK_ORDER_SORTING_KEY.TITLE}
              sort={sort}
              onClick={(sortValue) => {
                setSort(sortValue);
              }}
            />
            <DataTableColumnResizer header={header} scope={WORK_ORDER} />
          </div>
        ),
        Cell: (info) => (
          <BodyText
            size={BODY_TEXT_SIZES.X_SMALL}
            className="max-w-full truncate"
          >
            {info.cell.getValue()}
          </BodyText>
        ),
      },
      {
        accessorKey: "status",
        size: sizeConfigs["status"],
        minSize: TABLE_VIEW_COLUMN_MIN_WIDTH,
        onClick: (id) => {
          handleCellClick(id);
        },
        Header: ({ header }) => (
          <div className="table-view-head-item">
            <span className="flex items-center">
              <TableStatusIcon width={16} className="text-secondary" />
            </span>
            <BodyText
              size={BODY_TEXT_SIZES.X_SMALL}
              color="text-secondary"
              className="truncate"
            >
              {messages?.tickets?.statusText}
            </BodyText>
            <DataTableColumnResizer header={header} scope={WORK_ORDER} />
          </div>
        ),
        Cell: (info) => {
          const status = oem?.statuses?.find(
            (item) => item._id === info.cell.getValue(),
          );

          return (
            <div className="overflow-hidden">
              <BodyText
                size={BODY_TEXT_SIZES.X_SMALL}
                className="whitespace-nowrap inline-block py-2xs px-xs font-semibold uppercase rounded-md"
                style={{
                  color: status?.color,
                  backgroundColor: generateStatusBackgroundColor(status?.color),
                }}
              >
                {status?.label}
              </BodyText>
            </div>
          );
        },
      },
      {
        accessorFn: (row) => row.facility?.name,
        minSize: TABLE_VIEW_COLUMN_MIN_WIDTH,
        size: sizeConfigs["Facility"],
        id: "Facility",
        onClick: (id) => {
          handleCellClick(id);
        },
        Header: ({ header }) => (
          <div className="table-view-head-item">
            <span className="flex items-center">
              <SidebarFacilityIcon width={16} className="text-secondary" />
            </span>
            <BodyText
              size={BODY_TEXT_SIZES.X_SMALL}
              color="text-secondary"
              className="truncate"
            >
              {isGrowthLoopEnabled
                ? messages["connections"]["connectionText"]
                : messages["facilities"]["facilityText"]}
            </BodyText>
            <DataTableColumnResizer header={header} scope={WORK_ORDER} />
          </div>
        ),
        Cell: (info) => (
          <BodyText
            size={BODY_TEXT_SIZES.X_SMALL}
            className="max-w-full truncate"
          >
            {info.cell.getValue("facility") ||
              messages?.tickets?.deletedFacility}
          </BodyText>
        ),
      },
      {
        accessorFn: (row) => row.machine?.name,
        minSize: TABLE_VIEW_COLUMN_MIN_WIDTH,
        size: sizeConfigs["Machine"],
        id: "Machine",
        onClick: (id) => {
          handleCellClick(id);
        },
        Header: ({ header }) => (
          <div className="table-view-head-item">
            <span className="flex items-center">
              <SidebarMachinesIcon width={16} className="text-secondary" />
            </span>
            <BodyText
              size={BODY_TEXT_SIZES.X_SMALL}
              color="text-secondary"
              className="truncate"
            >
              {messages["hierarchy"]["assets"]["nameTextSingular"]}
            </BodyText>
            <DataTableColumnResizer header={header} scope={WORK_ORDER} />
          </div>
        ),
        Cell: (info) => (
          <BodyText
            size={BODY_TEXT_SIZES.X_SMALL}
            className="max-w-full truncate"
          >
            {info.cell.getValue("machine") ||
              messages["hierarchy"]["assets"]["deletedAsset"]}
          </BodyText>
        ),
      },

      {
        accessorFn: (row) => row.createdBy?.name,
        minSize: TABLE_VIEW_COLUMN_MIN_WIDTH,
        size: sizeConfigs["createdBy"],
        id: "createdBy",
        onClick: (id) => {
          handleCellClick(id);
        },
        Header: ({ header }) => (
          <div className="table-view-head-item">
            <span className="flex items-center">
              <User size={16} className="text-secondary" />
            </span>

            <BodyText
              size={BODY_TEXT_SIZES.X_SMALL}
              color="text-secondary"
              className="truncate"
            >
              {messages?.tickets?.ticketDetails.detailsListing.createdBy}
            </BodyText>
            <DataTableColumnResizer header={header} scope={WORK_ORDER} />
          </div>
        ),
        Cell: (info) => (
          <div className="ticket-meta-data">
            <Avatar
              name={
                info.cell.getValue("createdBy") || messages?.common?.noAssignee
              }
              showName
              nameClassName="truncate"
              hasNoUser={!info.cell.getValue("createdBy")}
            />
          </div>
        ),
      },
      {
        accessorFn: (row) => row.user?.name,
        minSize: TABLE_VIEW_COLUMN_MIN_WIDTH,
        size: sizeConfigs["Reporter"],
        id: "Reporter",
        onClick: (id) => {
          handleCellClick(id);
        },
        Header: ({ header }) => (
          <div className="table-view-head-item">
            <span className="flex items-center">
              <User size={16} className="text-secondary" />
            </span>

            <BodyText
              size={BODY_TEXT_SIZES.X_SMALL}
              color="text-secondary"
              className="truncate"
            >
              {
                messages?.tickets?.ticketDetails?.detailsListing?.[
                  isGrowthLoopEnabled ? "reporterGrowthLoop" : "reporter"
                ]
              }
            </BodyText>
            <DataTableColumnResizer header={header} scope={WORK_ORDER} />
          </div>
        ),
        Cell: (info) => (
          <div className="ticket-meta-data">
            <Avatar
              name={
                info.cell.getValue("report") || messages?.common?.noAssignee
              }
              showName
              nameClassName="truncate"
              hasNoUser={!info.cell.getValue("report")}
            />
          </div>
        ),
      },
      {
        accessorFn: (row) => row.assignees,
        minSize: TABLE_VIEW_COLUMN_MIN_WIDTH,
        size: sizeConfigs["Assignee"],
        id: "Assignee",
        onClick: (id) => {
          handleCellClick(id);
        },
        Header: ({ header }) => (
          <div className="table-view-head-item">
            <span className="flex items-center">
              <User size={16} className="text-secondary" />
            </span>

            <BodyText
              size={BODY_TEXT_SIZES.X_SMALL}
              color="text-secondary"
              className="truncate"
            >
              {messages?.tickets?.ticketDetails?.detailsListing?.assignee}
            </BodyText>
            <DataTableColumnResizer header={header} scope={WORK_ORDER} />
          </div>
        ),
        Cell: (info) => (
          <div className="ticket-meta-data ticket-item-table-assignees">
            <TicketItemAssigneesList
              assignees={info.cell.getValue()}
              tooltipPlace="bottom"
              className="mb-0"
              avatarSize={AVATAR_SIZES.SMALL}
            />
          </div>
        ),
      },
      {
        accessorFn: (row) =>
          row?.schedule?.startTime
            ? moment(row?.schedule?.startTime).format(DATE_FORMAT_YYYY_MM_DD)
            : null,
        minSize: TABLE_VIEW_COLUMN_MIN_WIDTH,
        size: sizeConfigs["schedule-date"],
        id: "schedule-date",
        onClick: (id) => {
          handleCellClick(id);
        },
        Header: ({ header }) => (
          <div className="table-view-head-item justify-between">
            <div className="flex flex-nowrap items-center overflow-hidden gap-xs">
              <span className="flex items-center">
                <CalendarIcon
                  width={16}
                  height={16}
                  className="text-secondary"
                />
              </span>
              <BodyText
                size={BODY_TEXT_SIZES.X_SMALL}
                color="text-secondary"
                className="truncate"
              >
                {messages?.common?.scheduleDate}
              </BodyText>
            </div>

            <SortingArrows
              identifier={WORK_ORDER_SORTING_KEY.CREATED_AT}
              sort={sort}
              onClick={(sortValue) => {
                setSort(sortValue);
              }}
            />
            <DataTableColumnResizer header={header} scope={WORK_ORDER} />
          </div>
        ),
        Cell: (info) => (
          <div className="ticket-meta-data flex items-center">
            <BodyText
              size={BODY_TEXT_SIZES.X_SMALL}
              className="max-w-full truncate"
            >
              {info.cell.getValue() || "-"}
            </BodyText>
          </div>
        ),
      },
    ];
  }, [oem?.paidFeatures, sort, appConfig?.plans]);

  const allColumns = useMemo(() => {
    let dynamicColumns = [];

    if (isPaidFeatureAvailable(PaidFeatures.procedures, user, appConfig)) {
      dynamicColumns.push({
        accessorFn: (row) => {
          return row.procedures?.length || 0;
        },
        minSize: TABLE_VIEW_COLUMN_MIN_WIDTH,
        size: sizeConfigs["Procedures"],
        id: "Procedures",
        hidden: !isPaidFeatureAvailable(
          PaidFeatures.procedures,
          user,
          appConfig,
        ),
        onClick: (id) => {
          handleCellClick(id);
        },
        Header: ({ header }) => (
          <div className="table-view-head-item">
            <span className="flex items-center">
              <SidebarProceduresIcon width={16} className="text-secondary" />
            </span>

            <BodyText
              size={BODY_TEXT_SIZES.X_SMALL}
              color="text-secondary"
              className="truncate"
            >
              {messages?.menus?.procedures?.title}
            </BodyText>
            <DataTableColumnResizer header={header} scope={WORK_ORDER} />
          </div>
        ),
        Cell: (info) => (
          <div className="ticket-meta-data flex items-center">
            <BodyText size={BODY_TEXT_SIZES.X_SMALL}>
              {info.cell.getValue()}
            </BodyText>
            <SidebarProceduresIcon width={16} />
          </div>
        ),
      });
    }

    if (customAdditionalFields?.length > 0) {
      dynamicColumns = generateCustomFieldColumns(
        dynamicColumns,
        customAdditionalFields,
        handleCellClick,
        sizeConfigs,
        WORK_ORDER,
      );
      dynamicColumns.push({
        accessorFn: (row) =>
          moment(row.createdAt).format(DATE_FORMAT_YYYY_MM_DD),
        minSize: TABLE_VIEW_COLUMN_MIN_WIDTH,
        size: sizeConfigs["creation-date"],
        id: "creation-date",
        onClick: (id) => {
          handleCellClick(id);
        },
        Header: ({ header }) => (
          <div className="table-view-head-item justify-between">
            <div className="flex flex-nowrap items-center overflow-hidden gap-xs">
              <span className="flex items-center">
                <CalendarIcon
                  width={16}
                  height={16}
                  className="text-secondary"
                />
              </span>
              <BodyText
                size={BODY_TEXT_SIZES.X_SMALL}
                color="text-secondary"
                className="truncate"
              >
                {messages?.common?.creationDate}
              </BodyText>
            </div>

            <SortingArrows
              identifier={WORK_ORDER_SORTING_KEY.CREATED_AT}
              sort={sort}
              onClick={(sortValue) => {
                setSort(sortValue);
              }}
            />
            <DataTableColumnResizer header={header} scope={WORK_ORDER} />
          </div>
        ),
        Cell: (info) => (
          <div className="ticket-meta-data flex items-center">
            <BodyText
              size={BODY_TEXT_SIZES.X_SMALL}
              className="max-w-full truncate"
            >
              {info.cell.getValue()}
            </BodyText>
          </div>
        ),
      });
    }

    return [...predefinedColumns, ...dynamicColumns];
  }, [predefinedColumns, customAdditionalFields, sort]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      {!tickets?.length ? (
        <NotFoundComponent
          title={messages?.tickets?.notFound.title}
          description={messages?.tickets?.notFound?.dsc}
          showDescription
          showCTA={true}
          ctaJSX={
            <CreateTicketDrawer
              onOpenDrawer={() =>
                registerMixpanelEvent(INITIATE_ADD_WORK_ORDER)
              }
              onSubmitSuccess={() =>
                registerMixpanelEvent(SUBMIT_NEW_WORK_ORDER, {
                  workOrderSource:
                    MIXPANEL_EVENT_PROPERTIES.workOrderSource.workOrders,
                })
              }
            />
          }
        />
      ) : (
        <DataTable
          columns={allColumns}
          data={tickets}
          totalCount={totalCount}
          onScrollToBottom={onScrollToBottom}
          isDataLoading={isLoading}
        />
      )}
    </div>
  );
};

export default TableView;
