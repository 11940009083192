import { gql } from "@apollo/client";

import {
  AiAssistant,
  CallInfo,
  Customer,
  CustomizeField,
  InventoryPart,
  Oem,
  PreventiveMaintenance,
  ProcedureInstance,
  ProcedureTemplate,
  Report,
  Resource,
  Team,
  Ticket,
  TimeTracker,
  User,
  WorkOrderType,
  Schedule,
  MachineHistory,
  TicketScheduleDraft,
  AiNote,
  AssetTemplate,
  Asset,
  Document,
} from "~/api/_fragments";
import {
  CREATE_CONTACT,
  DELETE_CONTACT,
  INVITE_CONTACTS,
  REMOVE_CONTACT_INVITE,
  RESEND_CONTACT_INVITE,
  UPDATE_CONTACT,
} from "~/services/contact/constants";
import {
  APPROVE_OCR_DOCUMENTS,
  CREATE_DOCUMENTS,
  DELETE_DOCUMENTS,
  INDEX_DOCUMENTS,
  SCAN_DOCUMENTS,
  UPDATE_DOCUMENT,
} from "~/services/document/constants";
import {
  CREATE_GUIDE,
  DELETE_GUIDE,
  UPDATE_GUIDE,
} from "~/services/guides/constants";
import { CREATE_REQUEST } from "~/services/request/constants";

export const ADD_AI_ASSISTANT_DOCUMENTS = "addAiAssistantDocuments";
export const CREATE_AI_ASSISTANT = "createAiAssistant";
export const REMVOVE_AI_ASSISTANT = "removeAiAssistant";
export const REMOVE_AI_ASSISTANT_DOCUMENTS = "removeAiAssistantDocuments";
export const RENAME_AI_ASSISTANT = "renameAiAssistant";

// REMOTE
export const LOGIN_MUTATION = gql`
  mutation loginOemDashboard($userCredentials: userOemCredentials!) {
    loginOemDashboard(input: $userCredentials)
  }
`;

export const FORGOT_PASSWORD_MUTATION = gql`
  mutation forgotPassword($email: String!) {
    forgotPassword(email: $email)
  }
`;

export const CREATE_OEM_SUPPORT_ACCOUNT_MUTATION = gql`
  mutation createOemSupportAccount($input: InputCreateOemSupportAccount!) {
    createOemSupportAccount(input: $input) {
      ...UserBasicData
    }
  }
  ${User.fragments.UserBasicData}
`;

export const CUSTOMER_PORTAL_SIGNUP_MUTATION = gql`
  mutation customerPortalSignup($input: InputCustomerPortalSignup!) {
    customerPortalSignup(input: $input)
  }
`;

export const UPDATE_OEM_SUPPORT_ACCOUNT_MUTATION = gql`
  mutation updateOemSupportAccount($input: InputUpdateOemSupportAccount!) {
    updateOemSupportAccount(input: $input) {
      ...UserBasicData
    }
  }
  ${User.fragments.UserBasicData}
`;

export const DELETE_OEM_SUPPORT_ACCOUNT_MUTATION = gql`
  mutation deleteOemSupportAccount($id: ID!) {
    deleteOemSupportAccount(id: $id)
  }
`;

export const RAISE_OEM_SUPPORT_QUERY_MUTATION = gql`
  mutation raiseOemSupportQuery(
    $companySize: String
    $contact: String
    $query: String
    $isDelete: Boolean
    $productType: String
  ) {
    raiseOemSupportQuery(
      companySize: $companySize
      contact: $contact
      query: $query
      isDelete: $isDelete
      productType: $productType
    )
  }
`;

export const CREATE_OEM_CUSTOMER_MUTATION = gql`
  mutation createOemCustomer($input: InputCreateOemCustomer!) {
    createOemCustomer(input: $input) {
      ...CustomerBasicData
    }
  }
  ${Customer.fragments.CustomerBasicData}
`;

export const CREATE_OEM_CUSTOMER_V2_MUTATION = gql`
  mutation createOemCustomerV2($input: InputCreateOemCustomerV2!) {
    createOemCustomerV2(input: $input) {
      ...CustomerFullData
    }
  }
  ${Customer.fragments.CustomerFullData}
`;

export const UPDATE_OEM_CUSTOMER_MUTATION = gql`
  mutation updateOemCustomer($input: InputUpdateOemCustomer!) {
    updateOemCustomer(input: $input) {
      ...CustomerFullData
    }
  }
  ${Customer.fragments.CustomerFullData}
`;

export const DELETE_OEM_CUSTOMER_MUTATION = gql`
  mutation deleteOemCustomer($customerId: ID!) {
    deleteOemCustomer(customerId: $customerId)
  }
`;

export const UPDATE_OWN_OEM_MUTATION = gql`
  mutation updateOwnOem($input: InputUpdateOwnOem!) {
    updateOwnOem(input: $input) {
      ...OemBasicData
    }
  }
  ${Oem.fragments.OemBasicData}
`;

export const CREATE_OEM_MUTATION = gql`
  mutation createOem($input: InputCreateOem!) {
    createOem(input: $input) {
      _id
    }
  }
`;

export const CREATE_OEM_FACILITY_USER_MUTATION = gql`
  mutation createOemFacilityUser($input: InputCreateFacilityUser!) {
    createOemFacilityUser(input: $input) {
      ...UserBasicData
    }
  }
  ${User.fragments.UserBasicData}
`;

export const UPDATE_OEM_FACILITY_USER_MUTATION = gql`
  mutation updateOemFacilityUser($input: InputUpdateFacilityUser!) {
    updateOemFacilityUser(input: $input) {
      ...UserBasicData
    }
  }
  ${User.fragments.UserBasicData}
`;

export const DELETE_OEM_FACILITY_USER_MUTATION = gql`
  mutation deleteOemFacilityUser($id: ID!) {
    deleteOemFacilityUser(id: $id)
  }
`;

export const UPDATE_OWN_OEM_TICKET_MUTATION = gql`
  mutation updateOwnOemTicket($input: InputUpdateOwnOemTicket!) {
    updateOwnOemTicket(input: $input) {
      ...TicketSingleData
    }
  }
  ${Ticket.fragments.TicketSingleData}
`;

export const ADD_TICKET_RESOURCE_MUTATION = gql`
  mutation addTicketResource($input: InputAddTicketResource!) {
    addTicketResource(input: $input) {
      ...TicketSingleData
    }
  }
  ${Ticket.fragments.TicketSingleData}
`;

export const REMOVE_TICKET_RESOURCES_MUTATION = gql`
  mutation removeTicketResources($input: InputRemoveTicketResources!) {
    removeTicketResources(input: $input) {
      ...TicketSingleData
    }
  }
  ${Ticket.fragments.TicketSingleData}
`;

export const UPDATE_TICKET_KANBAN_OWN_OEM_TICKET_MUTATION = gql`
  mutation updateOwnOemKanbanBoardTicketCard(
    $input: InputUpdateOwnOemKanbanBoardTicketCard!
  ) {
    updateOwnOemKanbanBoardTicketCard(input: $input)
  }
`;

export const CREATE_OWN_OEM_TICKET_MUTATION = gql`
  mutation createOwnOemTicket($input: InputCreateOwnOemTicket!) {
    createOwnOemTicket(input: $input) {
      ...TicketSingleData
    }
  }
  ${Ticket.fragments.TicketSingleData}
`;

export const JOIN_CALL_FOR_TICKET_FROM_FACILITY_MUTATION = gql`
  mutation joinCallForTicketFromFacility($channel: String!) {
    joinCallForTicketFromFacility(channel: $channel) {
      ...CallInfoData
    }
  }
  ${CallInfo.fragments.CallInfoData}
`;

export const UPDATE_PERSONAL_SETTINGS = gql`
  mutation updatePersonalSettings(
    $personalSettings: InputUpdatePersonalSettings
    $emailNotificationSettings: InputEmailNotificationSettings
  ) {
    updatePersonalSettings(
      personalSettings: $personalSettings
      emailNotificationSettings: $emailNotificationSettings
    ) {
      ...UserBasicData
    }
  }
  ${User.fragments.UserBasicData}
`;

export const CHECK_VALID_FACILITY_USER_MUTATION = gql`
  mutation checkValidFacilityUser($userId: ID!) {
    checkValidFacilityUser(userId: $userId)
  }
`;
export const ACTIVE_INACTIVE_OWN_OEM_ADDITIONAL_FIELD = gql`
  mutation activeInactiveOwnOemAdditionalField($id: ID!, $enabled: Boolean!) {
    activeInactiveOwnOemAdditionalField(_id: $id, enabled: $enabled) {
      ...CustomAdditionFieldData
    }
  }
  ${CustomizeField.fragments.CustomAdditionFieldData}
`;

export const CREATE_OWN_OEM_CUSTOM_ADDITION_FIELD = gql`
  mutation createOwnOemCustomField($input: InputInsertCustomAdditionalField) {
    createOwnOemCustomField(input: $input) {
      ...CustomAdditionFieldData
    }
  }
  ${CustomizeField.fragments.CustomAdditionFieldData}
`;
export const UPDATE_OWN_OEM_CUSTOM_ADDITION_FIELD = gql`
  mutation updateOwnOemCustomField($input: InputUpdateCustomAdditionalField) {
    updateOwnOemCustomField(input: $input) {
      ...CustomAdditionFieldData
    }
  }
  ${CustomizeField.fragments.CustomAdditionFieldData}
`;
export const UPDATE_OWN_OEM_CUSTOM_FIELDS_ORDER = gql`
  mutation updateOwnOemCustomFieldsOrder($input: InputUpdateCustomFieldsOrder) {
    updateOwnOemCustomFieldsOrder(input: $input)
  }
`;

export const DELETE_OWN_OEM_CUSTOM_ADDITIONAL_FIELD = gql`
  mutation deleteOwnOemCustomAdditionalField(
    $input: InputDeleteOwnOemCustomAdditionalField
  ) {
    deleteOwnOemCustomAdditionalField(input: $input)
  }
`;

export const CREATE_PREVENTIVE_MAINTENANCE_EVENT = gql`
  mutation createPreventiveMaintenanceEvent(
    $input: InputCreatePreventiveMaintenanceEvent!
  ) {
    createPreventiveMaintenanceEvent(input: $input) {
      ...PreventiveEventData
    }
  }
  ${PreventiveMaintenance.fragments.PreventiveEventData}
`;

export const UPDATE_PREVENTIVE_MAINTENANCE_EVENT = gql`
  mutation updatePreventiveMaintenanceEvent(
    $input: InputUpdatePreventiveMaintenanceEvent!
  ) {
    updatePreventiveMaintenanceEvent(input: $input) {
      ...PreventiveEventData
    }
  }
  ${PreventiveMaintenance.fragments.PreventiveEventData}
`;

export const DELETE_PREVENTIVE_MAINTENANCE_EVENT = gql`
  mutation deletePreventiveMaintenanceEvent($id: ID!) {
    deletePreventiveMaintenanceEvent(id: $id) {
      _id
      oem {
        _id
      }
    }
  }
`;

export const NOTIFY_ON_INTERNAL_POST_MUTATION = gql`
  mutation notifyOnInternalNotePost($input: InputNotifyOnInternalNotePost!) {
    notifyOnInternalNotePost(input: $input)
  }
`;

export const CREATE_OWN_OEM_INVENTORY_PART_MUTATION = gql`
  mutation createOwnOemInventoryPart($input: InputCreateInventoryPart!) {
    createOwnOemInventoryPart(input: $input) {
      ...InventoryPartFullData
    }
  }
  ${InventoryPart.fragments.InventoryPartFullData}
`;

export const UPDATE_OWN_OEM_INVENTORY_PART_MUTATION = gql`
  mutation updateOwnOemInventoryPart($input: InputUpdateInventoryPart!) {
    updateOwnOemInventoryPart(input: $input) {
      ...InventoryPartFullData
    }
  }
  ${InventoryPart.fragments.InventoryPartFullData}
`;

export const DELETE_OWN_OEM_INVENTORY_PART_MUTATION = gql`
  mutation deleteOwnOemInventoryPart($id: ID!) {
    deleteOwnOemInventoryPart(id: $id) {
      _id
    }
  }
`;

export const ADD_OWN_OEM_PART_TO_TICKET_MUTATION = gql`
  mutation assignOwnOemInventoryPartsToTicket(
    $input: InputAssignInventoryPartsToTicket!
  ) {
    assignOwnOemInventoryPartsToTicket(input: $input) {
      ...TicketSingleData
    }
  }
  ${Ticket.fragments.TicketSingleData}
`;

export const REMOVE_OWN_OEM_PART_FROM_TICKET_MUTATION = gql`
  mutation removeOwnOemInventoryPartFromTicket(
    $input: InputRemoveInventoryPartFromTicket!
  ) {
    removeOwnOemInventoryPartFromTicket(input: $input) {
      ...TicketSingleData
    }
  }
  ${Ticket.fragments.TicketSingleData}
`;

export const CREATE_TIME_TRACKER_TAG_MUTATION = gql`
  mutation createOwnOemTimeTrackerTag($input: InputInsertTag!) {
    createOwnOemTimeTrackerTag(input: $input) {
      ...TimeTrackerBasicData
    }
  }
  ${TimeTracker.fragments.TimeTrackerBasicData}
`;

export const UPDATE_TIME_TRACKER_TAG_MUTATION = gql`
  mutation updateOwnOemTimeTrackerTag($input: InputUpdateTag!) {
    updateOwnOemTimeTrackerTag(input: $input) {
      ...TimeTrackerBasicData
    }
  }
  ${TimeTracker.fragments.TimeTrackerBasicData}
`;

export const DELETE_TIME_TRACKER_TAG_MUTATION = gql`
  mutation deleteOwnOemTimeTrackerTag($id: ID!) {
    deleteOwnOemTimeTrackerTag(id: $id) {
      ...TimeTrackerBasicData
    }
  }
  ${TimeTracker.fragments.TimeTrackerBasicData}
`;

export const ADD_TICKET_TIME_TRACKER_LOG_MUTATION = gql`
  mutation addTimeTrackerLog($input: InputAddTimeTrackerLog!) {
    addTimeTrackerLog(input: $input) {
      ...TicketSingleData
    }
  }
  ${Ticket.fragments.TicketSingleData}
`;

export const UPDATE_TICKET_TIME_TRACKER_LOG_MUTATION = gql`
  mutation updateTimeTrackerLog($input: InputUpdateTimeTrackerLog!) {
    updateTimeTrackerLog(input: $input) {
      ...TicketSingleData
    }
  }
  ${Ticket.fragments.TicketSingleData}
`;

export const REMOVE_TIME_TRACKER_LOG_FROM_TICKET_MUTATION = gql`
  mutation removeTimeTrackerLogFromTicket($input: InputRemoveTimeTrackerLog!) {
    removeTimeTrackerLogFromTicket(input: $input) {
      ...TicketSingleData
    }
  }
  ${Ticket.fragments.TicketSingleData}
`;

export const UPDATE_TITLE_TICKET_MUTATION = gql`
  mutation updateTitleTicket($input: InputUpdateTitleTicket!) {
    updateTitleTicket(input: $input) {
      ...TicketSingleData
    }
  }
  ${Ticket.fragments.TicketSingleData}
`;

export const UPDATE_DESCRIPTION_TICKET_MUTATION = gql`
  mutation updateDescriptionTicket($input: InputUpdateDescriptionTicket!) {
    updateDescriptionTicket(input: $input) {
      ...TicketSingleData
    }
  }
  ${Ticket.fragments.TicketSingleData}
`;

export const HANDLE_IMPORT_UPLOAD_ID_MUTATION = gql`
  mutation handleImportUploadId($input: InputHandleImportUploadId!) {
    handleImportUploadId(input: $input)
  }
`;

export const SAVE_OWN_OEM_PROCEDURE_TEMPLATE = gql`
  mutation SaveOwnOemProcedureTemplate($input: InputSaveProcedureTemplate!) {
    saveOwnOemProcedureTemplate(input: $input) {
      ...ProcedureTemplateFullData
    }
  }
  ${ProcedureTemplate.fragments.ProcedureTemplateFullData}
`;

export const DELETE_OWN_OEM_PROCEDURE_TEMPLATE = gql`
  mutation deleteOwnOemProcedureTemplate($id: ID!) {
    deleteOwnOemProcedureTemplate(id: $id) {
      _id
    }
  }
`;
export const DUPLICATE_OWN_OEM_PROCEDURE_TEMPLATE = gql`
  mutation duplicateOwnOemProcedureTemplate($id: ID!) {
    duplicateOwnOemProcedureTemplate(id: $id)
  }
`;

export const ATTACH_OWN_OEM_PROCEDURE_TO_WORK_ORDER = gql`
  mutation attachOwnOemProcedureToWorkOrder(
    $input: InputAttachProcedureToWorkOrder!
  ) {
    attachOwnOemProcedureToWorkOrder(input: $input)
  }
`;

export const DETACH_OWN_OEM_PROCEDURE_TO_WORK_ORDER = gql`
  mutation detachOwnOemProcedureFromWorkOrder(
    $input: InputDetachProcedureFromWorkOrder!
  ) {
    detachOwnOemProcedureFromWorkOrder(input: $input) {
      _id
    }
  }
`;

export const SAVE_OWN_OEM_PROCEDURE_INSTANCE = gql`
  mutation saveOwnOemProcedure($input: InputSaveProcedure!) {
    saveOwnOemProcedure(input: $input) {
      ...ProcedureInstanceFullData
    }
  }
  ${ProcedureInstance.fragments.ProcedureInstanceFullData}
`;

export const ATTACH_PROCEDURE_TO_PM = gql`
  mutation attachProcedureToPreventiveMaintenance(
    $input: InputAttachProcedureToPM!
  ) {
    attachProcedureToPreventiveMaintenance(input: $input)
  }
`;

export const FINALIZE_OWN_OEM_PROCEDURE_INSTANCE = gql`
  mutation finalizeOwnOemProcedure($input: InputFinalizeProcedure!) {
    finalizeOwnOemProcedure(input: $input)
  }
`;

export const ADD_USER_BOARD = gql`
  mutation addUserBoard($board: InputUserBoard!) {
    addUserBoard(board: $board) {
      ...UserAuthData
    }
  }
  ${User.fragments.UserAuthData}
`;

export const REMOVE_USER_BOARD = gql`
  mutation removeUserBoard($board: InputUserBoard!) {
    removeUserBoard(board: $board) {
      ...UserAuthData
    }
  }
  ${User.fragments.UserAuthData}
`;

export const LINK_EMAIL_ACCOUNT_MUTATION = gql`
  mutation linkEmailAccount($code: String!) {
    linkEmailAccount(code: $code) {
      emailAccountLinked
      emailAccounts {
        emailAccountStatus
        isSynced
        emailAddress
        _id
      }
      maximumAllowedEmailAddresses
    }
  }
`;

export const UNLINK_EMAIL_ACCOUNT_MUTATION = gql`
  mutation unlinkEmailAccount($emailAddressId: ID!) {
    unlinkEmailAccount(emailAddressId: $emailAddressId) {
      emailAccountLinked
      emailAccounts {
        emailAccountStatus
        emailAddress
        _id
      }
      maximumAllowedEmailAddresses
    }
  }
`;

export const UPDATE_EMAIL_THREAD_MUTATION = gql`
  mutation updateEmailThread($input: InputUpdateEmailThread) {
    updateEmailThread(input: $input) {
      thread {
        id
        grantId
        earliestMessageDate
        hasAttachments
        latestMessageReceivedDate
        latestMessageSentDate
        messageIds
        messages {
          bcc {
            name
            email
          }
          cc {
            name
            email
          }
          date
          from {
            name
            email
          }
          id
          replyTo {
            name
            email
          }
          subject
          to {
            name
            email
          }
          unread
        }
        folders
        participants {
          name
          email
        }
        snippet
        subject
        unread
      }
      ticket {
        _id
        ticketId
      }
    }
  }
`;

export const MOVE_EMAIL_TO_FOLDER_MUTATION = gql`
  mutation moveEmailToFolder($input: InputMoveEmailToFolder!) {
    moveEmailToFolder(input: $input)
  }
`;

export const SEND_EMAIL_MUTATION = gql`
  mutation sendEmail($input: InputSendEmail) {
    sendEmail(input: $input)
  }
`;

export const LINK_TICKETS = gql`
  mutation linkTickets($ticketId: ID!, $linkedTicketId: ID!) {
    linkTickets(ticketId: $ticketId, linkedTicketId: $linkedTicketId) {
      _id
      linkedTickets {
        ticket {
          _id
          ticketId
          ticketType {
            _id
            name
            icon
            color
            isInternal
            isSystem
            descriptionConfig {
              show
              isRequired
              fieldName
            }
          }
          title
          status
          facility {
            name
          }
          machine {
            name
          }
        }
        linkedBy {
          _id
        }
        linkedAt
      }
    }
  }
`;

export const UNLINK_TICKETS = gql`
  mutation unlinkTickets($ticketId: ID!, $linkedTicketId: ID!) {
    unlinkTickets(ticketId: $ticketId, linkedTicketId: $linkedTicketId) {
      _id
      linkedTickets {
        ticket {
          _id
          ticketId
          ticketType {
            _id
            name
            icon
            color
            isInternal
            isSystem
            descriptionConfig {
              show
              isRequired
              fieldName
            }
          }
          title
          status
          facility {
            name
          }
          machine {
            name
          }
        }
        linkedBy {
          _id
        }
        linkedAt
      }
    }
  }
`;

export const ASSIGN_TEAM_TICKET_TYPES = gql`
  mutation assignTicketType($input: AssignTicketTypeInput!) {
    assignTicketType(input: $input) {
      oem {
        _id
      }
      name
      description
      createdAt
      _id
    }
  }
`;

export const CREATE_TEAM_MUTATION = gql`
  mutation createTeam($input: InputCreateTeam) {
    createTeam(input: $input) {
      oem {
        _id
      }
      name
      description
      createdAt
      _id
    }
  }
`;

export const DELETE_TEAM_MUTATION = gql`
  mutation deleteTeam($id: ID!) {
    deleteTeam(id: $id)
  }
`;

export const REMOVE_TICKET_TYPE_TEAM_MUTATION = gql`
  mutation removeTicketType($input: RemoveTicketTypeInput) {
    removeTicketType(input: $input) {
      oem {
        _id
      }
      name
      description
      createdAt
      _id
    }
  }
`;

export const UPDATE_TEAM_MUTATION = gql`
  mutation updateTeam($input: InputUpdateTeam!) {
    updateTeam(input: $input) {
      ...TeamData
    }
  }
  ${Team.fragments.TeamData}
`;

export const ASSIGN_MULTIPLE_MACHINES_TO_TEAM_MUTATION = gql`
  mutation assignMultipleMachinesToTeam(
    $input: InputAssignMultipleMachinesToTeam!
  ) {
    assignMultipleMachinesToTeam(input: $input) {
      _id
    }
  }
`;

export const ASSIGN_MULTIPLE_CUSTOMERS_TO_TEAM_MUTATION = gql`
  mutation assignMultipleCustomersToTeam(
    $input: InputAssignMultipleCustomersToTeam!
  ) {
    assignMultipleCustomersToTeam(input: $input) {
      _id
    }
  }
`;

export const ASSIGN_MULTIPLE_USERS_TO_TEAM_MUTATION = gql`
  mutation assignMultipleUsersToTeam($input: InputAssignMultipleUsersToTeam!) {
    assignMultipleUsersToTeam(input: $input) {
      _id
    }
  }
`;

export const UNASSIGN_CUSTOMER_FROM_TEAM_MUTATION = gql`
  mutation unassignCustomerFromTeam($input: InputUnassignCustomerFromTeam!) {
    unassignCustomerFromTeam(input: $input) {
      _id
    }
  }
`;

export const UNASSIGN_MACHINE_FROM_TEAM_MUTATION = gql`
  mutation unassignMachineFromTeam($input: InputUnassignMachineFromTeam!) {
    unassignMachineFromTeam(input: $input) {
      _id
    }
  }
`;

export const UNASSIGN_USER_FROM_TEAM_MUTATION = gql`
  mutation unassignUserFromTeam($input: InputUnassignUserFromTeam!) {
    unassignUserFromTeam(input: $input) {
      _id
    }
  }
`;

export const CREATE_MULTIPART_UPLOAD_MUTATION = gql`
  mutation (
    $filename: String!
    $filetype: String!
    $type: String
    $forceCustomPath: Boolean
    $fileSize: Int!
    $partSize: Int!
  ) {
    _createMultiPartUploadS3(
      filename: $filename
      filetype: $filetype
      type: $type
      forceCustomPath: $forceCustomPath
      fileSize: $fileSize
      partSize: $partSize
    ) {
      UploadId
      signedUrls
      fileKey
    }
  }
`;

export const COMPLETE_MULTIPART_UPLOAD_MUTATION = gql`
  mutation (
    $UploadId: String!
    $MultipartUpload: CompletedMultipartUpload
    $fileKey: String!
  ) {
    _completeMultiPartUploadS3(
      UploadId: $UploadId
      MultipartUpload: $MultipartUpload
      fileKey: $fileKey
    ) {
      url
    }
  }
`;

export const CREATE_OEM_TICKET_TYPE_MUTATION = gql`
  mutation createOemTicketType($input: InputTicketType!) {
    createOemTicketType(input: $input) {
      ...WorkOrderTypeData
    }
  }
  ${WorkOrderType.fragments.WorkOrderTypeData}
`;

export const UPDATE_OEM_TICKET_TYPE_MUTATION = gql`
  mutation updateOemTicketType($id: ID!, $input: InputUpdateOemTicketType!) {
    updateOemTicketType(id: $id, input: $input) {
      ...WorkOrderTypeData
    }
  }
  ${WorkOrderType.fragments.WorkOrderTypeData}
`;

export const DELETE_OEM_TICKET_TYPE_MUTATION = gql`
  mutation deleteOemTicketType($id: ID!, $input: InputDeleteOemTicketType) {
    deleteOemTicketType(id: $id, input: $input)
  }
`;

export const CONFIGURE_OEM_TICKET_TYPE_MUTATION = gql`
  mutation configureOemTicketType($id: ID!, $input: InputTicketTypeConfig!) {
    configureOemTicketType(id: $id, input: $input) {
      ...WorkOrderTypeData
    }
  }
  ${WorkOrderType.fragments.WorkOrderTypeData}
`;

export const CREATE_OWN_OEM_REPORT_MUTATION = gql`
  mutation createOwnOemReport($input: InputCreateOwnOemReport!) {
    createOwnOemReport(input: $input) {
      _id
      title
      entity
    }
  }
`;

export const CREATE_OWN_OEM_DUPLICATE_REPORT_MUTATION = gql`
  mutation duplicateOwnOemReport($input: InputDuplicateOwnOemReport!) {
    duplicateOwnOemReport(input: $input) {
      _id
    }
  }
`;

export const DELETE_REPORT_MUTATION = gql`
  mutation deleteOwnOemReport($id: ID!) {
    deleteOwnOemReport(id: $id)
  }
`;

export const UPDATE_REPORT_MUTATION = gql`
  mutation updateOwnOemReport($input: InputUpdateOwnOemReport!) {
    updateOwnOemReport(input: $input) {
      ...InputReportData
    }
  }
  ${Report.fragment.InputReportData}
`;

export const ADD_TICKET_FOLLOWER_MUTATION = gql`
  mutation addTicketFollower($input: InputAddTicketFollower!) {
    addTicketFollower(input: $input) {
      _id
      followers {
        ...UserBasicData
      }
    }
  }
  ${User.fragments.UserBasicData}
`;

export const REMOVE_TICKET_FOLLOWER_MUTATION = gql`
  mutation removeTicketFollower($input: InputRemoveTicketFollower!) {
    removeTicketFollower(input: $input) {
      _id
      followers {
        ...UserBasicData
      }
    }
  }
  ${User.fragments.UserBasicData}
`;

export const ADD_TICKET_ASSIGNEE_MUTATION = gql`
  mutation addTicketAssignee($input: InputAddTicketAssignee!) {
    addTicketAssignee(input: $input) {
      _id
      assignees {
        ...UserBasicData
      }
    }
  }
  ${User.fragments.UserBasicData}
`;

export const ADD_TICKET_ATTACHMENT_MUTATION = gql`
  mutation addTicketAttachments($input: InputAddTicketAttachments!) {
    addTicketAttachments(input: $input) {
      _id
      attachments {
        name
        type
        url
        size
      }
    }
  }
`;

export const REMOVE_TICKET_ATTACHMENT_MUTATION = gql`
  mutation removeTicketAttachment($input: InputRemoveTicketAttachment!) {
    removeTicketAttachment(input: $input) {
      _id
      ticketId
    }
  }
`;

export const REMOVE_TICKET_ASSIGNEE_MUTATION = gql`
  mutation removeTicketAssignee($input: InputRemoveTicketAssignee!) {
    removeTicketAssignee(input: $input) {
      _id
      assignees {
        ...UserBasicData
      }
      ...TicketScheduleData
    }
  }
  ${User.fragments.UserBasicData}
  ${Schedule.fragments.TicketScheduleData}
`;

export const CREATE_MACHINE_HISTORY_NOTE_MUTATION = gql`
  mutation createMachineHistoryNote($input: InputCreateMachineHistoryNote) {
    createMachineHistoryNote(input: $input) {
      ...MachineHistoryData
    }
  }
  ${MachineHistory.fragments.MachineHistoryData}
`;

export const UPDATE_MACHINE_HISTORY_NOTE_MUTATION = gql`
  mutation updateMachineHistoryNote($input: InputUpdateMachineHistoryNote!) {
    updateMachineHistoryNote(input: $input) {
      ...MachineHistoryData
    }
  }
  ${MachineHistory.fragments.MachineHistoryData}
`;

export const DELETE_MACHINE_HISTORY_NOTE_MUTATION = gql`
  mutation deleteMachineHistoryNote($machine: ID!, $id: ID!) {
    deleteMachineHistoryNote(machine: $machine, id: $id)
  }
`;

export const DELETE_OWN_OEM_TICKET_MUTATION = gql`
  mutation deleteOwnOemTicket($input: InputDeleteOwnOemTicket!) {
    deleteOwnOemTicket(input: $input)
  }
`;

export const ADD_AI_ASSISTANT_DOCUMENTS_MUTATION = gql`
  mutation ${ADD_AI_ASSISTANT_DOCUMENTS}($input: InputAddAiAssistantDocuments!) {
    ${ADD_AI_ASSISTANT_DOCUMENTS}(input: $input)
  }
`;

export const CREATE_AI_ASSISTANT_MUTATION = gql`
  mutation ${CREATE_AI_ASSISTANT}($machineID: ID!) {
    ${CREATE_AI_ASSISTANT}(machineID: $machineID) {
      ...BasicData
    }
  }
  ${AiAssistant.BasicData}
`;

export const REMVOVE_AI_ASSISTANT_MUTATION = gql`
  mutation ${REMVOVE_AI_ASSISTANT}($aiAssistantID: ID!) {
    ${REMVOVE_AI_ASSISTANT}(aiAssistantID: $aiAssistantID)
  }
`;

export const REMOVE_AI_ASSISTANT_DOCUMENTS_MUTATION = gql`
  mutation ${REMOVE_AI_ASSISTANT_DOCUMENTS}($input: InputRemoveAiAssistantDocuments!) {
    ${REMOVE_AI_ASSISTANT_DOCUMENTS}(input: $input)
  }
`;

export const RENAME_AI_ASSISTANT_MUTATION = gql`
  mutation ${RENAME_AI_ASSISTANT}($input: InputRenameAiAssistant!) {
    ${RENAME_AI_ASSISTANT}(input: $input)
  }
`;

export const CREATE_OWN_OEM_SKILLS_MUTATION = gql`
  mutation createOwnOemSkills($input: InputCreateOwnOemSkills!) {
    createOwnOemSkills(input: $input) {
      skills {
        _id
        name
      }
    }
  }
`;

export const UPDATE_OWN_OEM_SKILL_MUTATION = gql`
  mutation updateOwnOemSkill($input: InputUpdateOwnOemSkill!) {
    updateOwnOemSkill(input: $input)
  }
`;

export const DELETE_OWN_OEM_SKILL_MUTATION = gql`
  mutation deleteOwnOemSkill($input: InputDeleteOwnOemSkill!) {
    deleteOwnOemSkill(input: $input)
  }
`;

export const CREATE_OEM_RESOURCE_MUTATION = gql`
  mutation createOwnOemResource($input: InputCreateOwnOemResource!) {
    createOwnOemResource(input: $input) {
      ...ResourceData
    }
  }
  ${Resource.fragments.ResourceData}
`;

export const UPDATE_OEM_RESOURCE_MUTATION = gql`
  mutation updateOwnOemResource($input: InputUpdateOwnOemResource!) {
    updateOwnOemResource(input: $input) {
      ...ResourceData
    }
  }
  ${Resource.fragments.ResourceData}
`;

export const DELETE_OEM_RESOURCE_MUTATION = gql`
  mutation deleteOwnOemResource($input: InputDeleteOwnOemResource!) {
    deleteOwnOemResource(input: $input)
  }
`;

export const ASSIGN_MULTIPLE_SKILLS_USER_MUTATION = gql`
  mutation assignMultipleSkillsToOemUser(
    $input: InputAssignUnassignMultipleSkillsOemUser!
  ) {
    assignMultipleSkillsToOemUser(input: $input) {
      _id
      skills {
        _id
      }
    }
  }
`;

export const UPDATE_TICKET_SCHEDULE_DRAFT = gql`
  mutation updateTicketScheduleDraft($input: InputUpdateTicketScheduleDraft!) {
    updateTicketScheduleDraft(input: $input) {
      ...TicketScheduleDraftData
    }
  }
  ${TicketScheduleDraft.fragments.TicketScheduleDraftData}
`;

export const PUBLISH_TICKET_SCHEDULE_DRAFT = gql`
  mutation publishTicketScheduleDraft(
    $input: InputPublishTicketScheduleDraft!
  ) {
    publishTicketScheduleDraft(input: $input) {
      ...TicketScheduleDraftData
    }
  }
  ${TicketScheduleDraft.fragments.TicketScheduleDraftData}
`;

export const LINK_CALENDAR_SYNC_ACCOUNT_MUTATION = gql`
  mutation linkCalendarSyncAccount($code: String!) {
    linkCalendarSyncAccount(code: $code) {
      calendarSyncAccountLinked
      calendarSyncAccountStatus
      linkedCalendarSyncEmailAddress
    }
  }
`;

export const UNLINK_CALENDAR_SYNC_ACCOUNT_MUTATION = gql`
  mutation unlinkCalendarSyncAccount {
    unlinkCalendarSyncAccount {
      calendarSyncAccountLinked
      calendarSyncAccountStatus
      linkedCalendarSyncEmailAddress
    }
  }
`;

export const CREATE_GUIDE_MUTATION = gql`
  mutation ${CREATE_GUIDE}($input: InputCreate3DGuide!) {
    ${CREATE_GUIDE}(input: $input) {
      _id
      machine {
        ...AssetFullData
      }
      oem
      sessionId
      name
      image
      createdBy {
        _id
        name
      }
    }
  }
  ${Asset.fragments.AssetFullData}
`;

export const CREATE_REQUEST_MUTATION = gql`
  mutation ${CREATE_REQUEST}($input: InputCreateRequest!) {
    ${CREATE_REQUEST}(input: $input) {
      ...TicketSingleData
    }
  }
  ${Ticket.fragments.TicketSingleData}
`;
export const UPDATE_GUIDE_MUTATION = gql`
  mutation ${UPDATE_GUIDE}($input: InputUpdate3DGuide!) {
    ${UPDATE_GUIDE}(input: $input) {
      _id
      machine {
        ...AssetFullData
      }
      oem
      sessionId
      name
      image
      createdBy {
        _id
        name
      }
    }
  }
  ${Asset.fragments.AssetFullData}
`;

export const DELETE_GUIDE_MUTATION = gql`
  mutation ${DELETE_GUIDE}($id: ID!) {
    ${DELETE_GUIDE}(id: $id)
  }
`;

export const DELETE_AI_NOTE_MUTATION = gql`
  mutation deleteAiNote($id: ID!) {
    deleteAiNote(id: $id)
  }
`;

export const PUBLISH_AI_NOTE_MUTATION = gql`
  mutation publishAiNote($input: InputPublishAiNote!) {
    publishAiNote(input: $input) {
      ...AiNoteData
    }
  }
  ${AiNote.fragments.AiNoteData}
`;

export const TRANSLATE_AI_NOTE_SUMMARY_MUTATION = gql`
  mutation translateAiNoteSummary($input: InputTranslateAiNoteSummary!) {
    translateAiNoteSummary(input: $input)
  }
`;

export const UPDATE_AI_NOTE_SUMMARY_MUTATION = gql`
  mutation updateAiNoteSummary($input: InputUpdateAiNoteSummary!) {
    updateAiNoteSummary(input: $input) {
      ...AiNoteData
    }
  }
  ${AiNote.fragments.AiNoteData}
`;

export const UPDATE_AI_NOTE_TITLE_MUTATION = gql`
  mutation updateAiNoteTitle($input: InputUpdateAiNoteTitle!) {
    updateAiNoteTitle(input: $input) {
      ...AiNoteData
    }
  }
  ${AiNote.fragments.AiNoteData}
`;

export const UPDATE_USER_EMAIL_SIGNATURE_MUTATION = gql`
  mutation updateEmailSignature(
    $signature: String!
    $autoAppendEmailSignature: Boolean!
  ) {
    updateEmailSignature(
      signature: $signature
      autoAppendEmailSignature: $autoAppendEmailSignature
    ) {
      emailSignature
      autoAppendEmailSignature
    }
  }
`;

export const CREATE_OWN_OEM_ASSET_TYPE_MUTATION = gql`
  mutation createOwnOemAssetType($input: InputCreateOwnOemAssetType!) {
    createOwnOemAssetType(input: $input) {
      assetTypes {
        _id
        name
      }
    }
  }
`;

export const UPDATE_OWN_OEM_ASSET_TYPE_MUTATION = gql`
  mutation updateOwnOemAssetType($input: InputUpdateOwnOemAssetType!) {
    updateOwnOemAssetType(input: $input)
  }
`;

export const DELETE_OWN_OEM_ASSET_TYPE_MUTATION = gql`
  mutation deleteOwnOemAssetType($input: InputDeleteOwnOemAssetType!) {
    deleteOwnOemAssetType(input: $input)
  }
`;

export const ASSIGN_OWN_OEM_INVENTORY_PARTS_TO_ASSET_TEMPLATE_MUTATION = gql`
  mutation assignOwnOemInventoryPartsToAssetTemplate(
    $input: InputAssignAssetTemplateInventoryParts
  ) {
    assignOwnOemInventoryPartsToAssetTemplate(input: $input) {
      ...AssetTemplateFullData
    }
  }
  ${AssetTemplate.fragments.AssetTemplateFullData}
`;

export const DELETE_OWN_OEM_ASSET_TEMPLATE_MUTATION = gql`
  mutation deleteOwnOemAssetTemplate($templateId: ID!) {
    deleteOwnOemAssetTemplate(templateId: $templateId) {
      _id
    }
  }
`;

export const CREATE_OWN_OEM_ASSET_TEMPLATE_MUTATION = gql`
  mutation createOwnOemAssetTemplate($input: InputCreateAssetTemplate!) {
    createOwnOemAssetTemplate(input: $input) {
      ...AssetTemplateData
    }
  }
  ${AssetTemplate.fragments.AssetTemplateData}
`;

export const REMOVE_OWN_OEM_INVENTORY_PART_FROM_ASSET_TEMPLATE_MUTATION = gql`
  mutation removeOwnOemInventoryPartFromAssetTemplate(
    $input: InputRemoveAssetTemplateInventoryPart
  ) {
    removeOwnOemInventoryPartFromAssetTemplate(input: $input) {
      ...AssetTemplateFullData
    }
  }
  ${AssetTemplate.fragments.AssetTemplateFullData}
`;

export const UPDATE_OWN_OEM_ASSET_TEMPLATE_MUTATION = gql`
  mutation updateOwnOemAssetTemplate($input: InputUpdateAssetTemplate!) {
    updateOwnOemAssetTemplate(input: $input) {
      ...AssetTemplateData
    }
  }
  ${AssetTemplate.fragments.AssetTemplateData}
`;

export const ASSIGN_ASSETS_TO_PARENT_MUTATION = gql`
  mutation assignAssetsToParent($input: InputAssignAssetsToParent!) {
    assignAssetsToParent(input: $input) {
      ...AssetFullData
    }
  }
  ${Asset.fragments.AssetFullData}
`;

export const ASSIGN_MULTIPLE_ASSETS_TO_TEAM_MUTATION = gql`
  mutation assignMultipleAssetsToTeam(
    $input: InputAssignMultipleAssetsToTeam!
  ) {
    assignMultipleAssetsToTeam(input: $input) {
      _id
    }
  }
`;

export const ASSIGN_OWN_OEM_MULTIPLE_ASSETS_TO_OWN_OEM_CUSTOMER = gql`
  mutation assignOwnOemMultipleAssetsToOwnOemCustomer(
    $input: InputAssignOwnOemMultipleAssetsToOwnOemCustomer!
  ) {
    assignOwnOemMultipleAssetsToOwnOemCustomer(input: $input) {
      ...AssetData
    }
  }
  ${Asset.fragments.AssetData}
`;

export const ASSIGN_OWN_OEM_PARTS_TO_ASSET_MUTATION = gql`
  mutation assignOwnOemInventoryPartsToAsset(
    $input: InputAssignAssetInventoryParts
  ) {
    assignOwnOemInventoryPartsToAsset(input: $input) {
      ...AssetPartsData
    }
  }
  ${Asset.fragments.AssetPartsData}
`;

export const CREATE_OWN_OEM_ASSET_MUTATION = gql`
  mutation createOwnOemAsset($input: InputCreateOwnOemAsset!) {
    createOwnOemAsset(input: $input) {
      ...AssetData
    }
  }
  ${Asset.fragments.AssetData}
`;

export const DELETE_OEM_ASSET_MUTATION = gql`
  mutation deleteOwnOemAsset($assetId: ID!) {
    deleteOwnOemAsset(assetId: $assetId)
  }
`;

export const DELETE_OWN_OEM_ASSET_IMAGE = gql`
  mutation deleteOwnOemAssetImage($assetId: ID!) {
    deleteOwnOemAssetImage(assetId: $assetId) {
      ...AssetData
    }
  }
  ${Asset.fragments.AssetData}
`;

export const HANDLE_OWN_OEM_ASSET_QR_ACCESS_MUTATION = gql`
  mutation handleOwnOemAssetQRAccess($input: InputHandleOwnOemAssetQRAccess!) {
    handleOwnOemAssetQRAccess(input: $input) {
      ...AssetData
    }
  }
  ${Asset.fragments.AssetData}
`;

export const REMOVE_OWN_OEM_ASSET_FROM_OWN_OEM_CUSTOMER = gql`
  mutation removeOwnOemAssetFromOwnOemCustomer(
    $input: InputRemoveOwnOemAssetFromOwnOemCustomer!
  ) {
    removeOwnOemAssetFromOwnOemCustomer(input: $input) {
      ...AssetData
    }
  }
  ${Asset.fragments.AssetData}
`;

export const DELETE_OWN_OEM_ASSET_3D_MODEL_MUTATION = gql`
  mutation deleteOwnOemAsset3DModel($assetId: ID!) {
    deleteOwnOemAsset3DModel(assetId: $assetId) {
      ...AssetData
    }
  }
  ${Asset.fragments.AssetData}
`;

export const ADD_OWN_OEM_ASSET_3D_MODEL_MUTATION = gql`
  mutation addOwnOemAsset3DModel($id: ID!, $input: String!) {
    addOwnOemAsset3DModel(_id: $id, _3dModelUrl: $input) {
      ...AssetData
    }
  }
  ${Asset.fragments.AssetData}
`;

export const REMOVE_OWN_OEM_PART_FROM_ASSET_MUTATION = gql`
  mutation removeOwnOemInventoryPartFromAsset(
    $input: InputRemoveAssetInventoryPart
  ) {
    removeOwnOemInventoryPartFromAsset(input: $input) {
      ...AssetPartsData
    }
  }
  ${Asset.fragments.AssetPartsData}
`;

export const UNASSIGN_ASSET_FROM_PARENT_MUTATION = gql`
  mutation unassignAssetFromParent($input: InputUnassignAssetFromParent!) {
    unassignAssetFromParent(input: $input) {
      ...AssetFullData
    }
  }
  ${Asset.fragments.AssetFullData}
`;

export const UNASSIGN_ASSET_FROM_TEAM_MUTATION = gql`
  mutation unassignAssetFromTeam($input: InputUnassignAssetFromTeam!) {
    unassignAssetFromTeam(input: $input) {
      _id
    }
  }
`;

export const UPDATE_OWN_OEM_ASSET_MUTATION = gql`
  mutation updateOwnOemAsset($input: InputUpdateOwnOemAsset!) {
    updateOwnOemAsset(input: $input) {
      ...AssetFullData
    }
  }
  ${Asset.fragments.AssetFullData}
`;

export const CREATE_CONTACT_MUTATION = gql`
  mutation ${CREATE_CONTACT}($input: InputCreateContact!) {
    ${CREATE_CONTACT}(input: $input) {
      _id
      name
      email
      jobTitle
      landline
      phoneNumber
      oem
      user
      connection
    }
  }
`;

export const INVITE_CONTACTS_MUTATION = gql`
  mutation ${INVITE_CONTACTS}($input: InputInviteContacts!) {
    ${INVITE_CONTACTS}(input: $input)
  }
`;

export const REMOVE_CONTACT_INVITE_MUTATION = gql`
  mutation ${REMOVE_CONTACT_INVITE}($id: ID!) {
    ${REMOVE_CONTACT_INVITE}(id: $id)
  }
`;

export const RESEND_CONTACT_INVITE_MUTATION = gql`
  mutation ${RESEND_CONTACT_INVITE}($id: ID!) {
    ${RESEND_CONTACT_INVITE}(id: $id)
  }
`;

export const UPDATE_CONTACT_MUTATION = gql`
  mutation ${UPDATE_CONTACT}($input: InputUpdateContact!) {
    ${UPDATE_CONTACT}(input: $input) {
      _id
      name
      email
      jobTitle
      landline
      phoneNumber
      oem
      user
      connection
    }
  }
`;

export const DELETE_CONTACT_MUTATION = gql`
  mutation ${DELETE_CONTACT}($id: ID!) {
    ${DELETE_CONTACT}(id: $id)
  }
`;

export const DELETE_DOCUMENTS_MUTATION = gql`
  mutation ${DELETE_DOCUMENTS}($documentsToDelete: [String]) {
    ${DELETE_DOCUMENTS}(documentsToDelete: $documentsToDelete)
  }
`;

export const UPDATE_DOCUMENT_MUTATION = gql`
  mutation ${UPDATE_DOCUMENT}($input: InputUpdateDocument) {
    ${UPDATE_DOCUMENT}(input: $input) {
      ...DocumentData
    }
  }
  ${Document.fragments.DocumentData}
`;

export const SCAN_DOCUMENTS_MUTATION = gql`
  mutation ${SCAN_DOCUMENTS}($documentsToScan: [String]) {
    ${SCAN_DOCUMENTS}(documentsToScan: $documentsToScan)
  }
`;

export const INDEX_DOCUMENTS_MUTATION = gql`
  mutation ${INDEX_DOCUMENTS}($documentsToIndex: [String]) {
    ${INDEX_DOCUMENTS}(documentsToIndex: $documentsToIndex)
  }
`;

export const APPROVE_OCR_DOCUMENTS_MUTATION = gql`
  mutation ${APPROVE_OCR_DOCUMENTS}($documentsToApprove: [String]) {
    ${APPROVE_OCR_DOCUMENTS}(documentsToApprove: $documentsToApprove)
  }
`;

export const VERIFY_SIGNUP_TOKEN_MUTATION = gql`
  mutation verifySignUpToken($token: String!) {
    verifySignUpToken(token: $token) {
      email
      userExists
      isInvitationExpired
      id
      userAdded
      emailMismatch
    }
  }
`;

export const APPROVE_INVITE_MUTATION = gql`
  mutation approveInvite($token: String!) {
    approveInvite(token: $token)
  }
`;

export const DECLINE_INVITE_MUTATION = gql`
  mutation declineInvite($token: String!) {
    declineInvite(token: $token)
  }
`;

export const DETACH_OWN_OEM_ASSET_DOCUMENTATION = gql`
  mutation detachOwnOemAssetDocumentation($_id: ID!) {
    detachOwnOemAssetDocumentation(_id: $_id) {
      ...AssetFullData
    }
  }
  ${Asset.fragments.AssetFullData}
`;

export const RESET_OWN_OEM_ASSET_TO_TEMPLATE = gql`
  mutation resetOwnOemAssetToTemplate($input: InputResetAssetToTemplate!) {
    resetOwnOemAssetToTemplate(input: $input) {
      ...AssetFullData
    }
  }
  ${Asset.fragments.AssetFullData}
`;

export const GET_GENERAL_SIGNUP_TOKEN_MUTATION = gql`
  mutation getGeneralSignUpToken($input: InputGetGeneralSignUpToken!) {
    getGeneralSignUpToken(input: $input)
  }
`;

export const VERIFY_GENERAL_SIGNUP_TOKEN_MUTATION = gql`
  mutation verifyGeneralSignUpToken($token: String!) {
    verifyGeneralSignUpToken(token: $token) {
      email
      redirectUrl
    }
  }
`;

export const CREATE_DOCUMENTS_MUTATION = gql`
  mutation ${CREATE_DOCUMENTS}($input: InputCreateDocuments) {
    ${CREATE_DOCUMENTS}(input: $input)
  }
`;

export const REMOVE_FIRST_SIGN_IN_REDIRECT_URL_MUTATION = gql`
  mutation removeFirstSignInRedirectUrl {
    removeFirstSignInRedirectUrl {
      ...UserAuthData
    }
  }
  ${User.fragments.UserAuthData}
`;
