import React from "react";
import { useIntl } from "react-intl";

import { NoTicketsLargeIcon, NoTicketsSmallIcon } from "../../icons/icons";

export const NoTicketsSmall = ({ title }) => {
  const { messages: msg } = useIntl();
  return (
    <div className="no-tickets-wrapper">
      <span className="no-tickets-icon">
        <NoTicketsSmallIcon />
      </span>

      <p className="no-tickets-title">
        {title || `${msg.tickets?.noTicketFound}`}
      </p>
    </div>
  );
};

export const NoTicketsLarge = ({ title }) => {
  return (
    <div className="no-tickets-wrapper full-page">
      <span className="no-tickets-icon">
        <NoTicketsLargeIcon />
      </span>

      <p className="no-tickets-title">
        {title || `${msg.tickets?.noTicketFound}`}
      </p>
    </div>
  );
};
