import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import { SingleSelectDropdown } from "@shared/ui/Dropdowns";
import {
  Input,
  RadioButtonInput,
  DateInput,
  DATE_INPUT_DROP_POSITIONS,
} from "@shared/ui/Inputs";
import moment from "moment-timezone";
import { WarningCircle } from "phosphor-react";
import React, { useState } from "react";
import { useIntl } from "react-intl";

import {
  CUSTOM_FIELD_MAX_NUMBER_LENGTH,
  CUSTOM_FIELD_MAX_TEXT_CONTENT_LENGTH,
  CUSTOM_FIELD_TYPE,
  DATE_FORMAT_YYYY_MM_DD,
} from "#/src/constants";

const CustomFormElements = ({
  fields,
  onChange,
  formData,
  errorData,
  updateErrorData,
}) => {
  const { messages } = useIntl();

  const handleChange = (fieldId, value) => {
    const newData = { ...formData, [fieldId]: value };
    onChange(newData);
  };

  return (
    <div className="work-order-form-fields">
      {fields.map((field) => (
        <React.Fragment key={field._id}>
          {field.customAdditionalField.fieldType ===
          CUSTOM_FIELD_TYPE.DATE_FIELD ? (
            <DateInput
              showFormat
              dropPosition={DATE_INPUT_DROP_POSITIONS.AUTO}
              scrollContainerSelector=".drawer-content"
              errorMessage={errorData[field.customAdditionalField._id]}
              value={formData[field.customAdditionalField._id] || ""}
              onChange={(date) => {
                const formatedDate = moment(date).format(
                  DATE_FORMAT_YYYY_MM_DD,
                );
                const currentValue = formData[field.customAdditionalField._id];
                updateErrorData({
                  ...errorData,
                  [field.customAdditionalField._id]: "",
                });
                if (currentValue === formatedDate) {
                  handleChange(field.customAdditionalField._id, "");
                } else {
                  handleChange(field.customAdditionalField._id, formatedDate);
                }
              }}
              label={`${field.customAdditionalField.label}${
                field.isRequired ? " *" : ""
              }`}
              placeholder={messages?.common?.selectDateText}
            />
          ) : null}

          {field.customAdditionalField.fieldType ===
          CUSTOM_FIELD_TYPE.NUMBER_FIELD ? (
            <Input
              keyId={field.customAdditionalField._id + "-field"}
              type="number"
              value={formData[field.customAdditionalField._id]}
              errorMessage={errorData[field.customAdditionalField._id]}
              onChange={(e) => {
                if (e.target.value?.length <= CUSTOM_FIELD_MAX_NUMBER_LENGTH) {
                  updateErrorData({
                    ...errorData,
                    [field.customAdditionalField._id]: "",
                  });
                  handleChange(
                    field.customAdditionalField._id,
                    parseFloat(e.target.value) || "",
                  );
                }
              }}
              placeholder={messages?.common?.addNumberContent}
              label={`${field.customAdditionalField.label}${
                field.isRequired ? " *" : ""
              }`}
            />
          ) : null}

          {field.customAdditionalField.fieldType ===
          CUSTOM_FIELD_TYPE.TEXT_AREA_FIELD ? (
            <CustomFieldTextArea
              formData={formData}
              errorData={errorData}
              field={field}
              onChange={handleChange}
              updateErrorData={updateErrorData}
            />
          ) : null}

          {field.customAdditionalField.fieldType ===
          CUSTOM_FIELD_TYPE.SINGLE_SELECT ? (
            <SingleSelectCFDropdown
              formData={formData}
              errorData={errorData}
              field={field}
              onChange={handleChange}
              updateErrorData={updateErrorData}
            />
          ) : null}
        </React.Fragment>
      ))}
    </div>
  );
};

export default CustomFormElements;

const CustomFieldTextArea = ({
  field,
  formData,
  errorData,
  updateErrorData,
  onChange,
}) => {
  const { messages } = useIntl();
  const [count, setCount] = useState(0);

  const handleChange = (event) => {
    const value = event.target.value;
    const length = value.length;

    if (length <= CUSTOM_FIELD_MAX_TEXT_CONTENT_LENGTH) {
      setCount(length);
      updateErrorData({ ...errorData, [field.customAdditionalField._id]: "" });
      onChange(field.customAdditionalField._id, value);
    }
  };

  return (
    <div className="work-order-form-element">
      <Input
        keyId={field.customAdditionalField._id + "-field"}
        label={`${field.customAdditionalField.label}${
          field.isRequired ? " *" : ""
        }`}
        labelDescription={`${count} / ${CUSTOM_FIELD_MAX_TEXT_CONTENT_LENGTH}`}
        value={formData[field.customAdditionalField._id]}
        onChange={handleChange}
        errorMessage={errorData[field.customAdditionalField._id]}
        placeholder={messages?.common?.addTextContent}
      />
    </div>
  );
};

const SignleSelectCustomField = ({
  field,
  formData,
  errorData,
  updateErrorData,
  onChange,
}) => {
  const errorMessage = errorData[field.customAdditionalField._id];

  const handleSelect = (event, option) => {
    event.preventDefault();
    const value =
      formData[field.customAdditionalField._id] === option.value
        ? null
        : option.value;

    updateErrorData({ ...errorData, [field.customAdditionalField._id]: "" });
    onChange(field.customAdditionalField._id, value);
  };

  return (
    <div className="work-order-form-element">
      <BodyText
        size={BODY_TEXT_SIZES.X_SMALL}
        color="text-secondary"
        className="no-styles"
      >
        {`${field.customAdditionalField.label}${field.isRequired ? " *" : ""}`}
      </BodyText>
      {field.customAdditionalField.options.map((option) => (
        <div
          key={field._id + "_" + option._id}
          onClick={(e) => handleSelect(e, option)}
          className="u-flex u-items-center u-cursor-pointer"
        >
          <RadioButtonInput
            keyId={field._id + "_" + option._id}
            buttonOnly
            isChecked={
              option.value === formData[field.customAdditionalField._id]
            }
          />

          <BodyText
            size={BODY_TEXT_SIZES.X_SMALL}
            color="text-secondary"
            className="no-styles ml-sm"
          >
            {option.value}
          </BodyText>
        </div>
      ))}

      {errorMessage && (
        <p className="input-v2-error-label u-padding-l-0">
          <WarningCircle className="input-v2-error-icon" size={16} />
          {errorMessage}
        </p>
      )}
    </div>
  );
};

const SingleSelectCFDropdown = ({
  field,
  formData,
  errorData,
  updateErrorData,
  onChange,
}) => {
  const { messages } = useIntl();
  const errorMessage = errorData[field.customAdditionalField._id];

  const handleSelect = (option) => {
    const value =
      formData[field.customAdditionalField._id] === option.value
        ? null
        : option.value;

    updateErrorData({ ...errorData, [field.customAdditionalField._id]: "" });
    onChange(field.customAdditionalField._id, value);
  };

  const allOptions = field.customAdditionalField.options.map((option) => ({
    isChecked: option.value === formData[field.customAdditionalField._id],
    value: option.value,
    label: option.value,
    keyId: field._id + "_" + option._id,
    isRequired: option.isRequired,
  }));

  return (
    <SingleSelectDropdown
      keyId="select-customAdditionalField-single-select"
      label={`${field.customAdditionalField.label}${
        field.isRequired ? " *" : ""
      }`}
      placeholder={messages?.tickets?.selectOptions}
      options={allOptions}
      disabled={Boolean(formData.length)}
      // @ts-ignore
      searchable
      searchBy="label"
      onChange={(selected) => handleSelect(selected)}
      errorMessage={errorMessage}
      customOptionItem={(Option) => {
        return (
          <BodyText
            key={Option.keyId}
            size={BODY_TEXT_SIZES.X_SMALL}
            color="text-secondary"
            className="no-styles"
          >
            {`${Option.label}${Option.isRequired ? " *" : ""}`}
          </BodyText>
        );
      }}
    />
  );
};
