import NoTicketsIcon from "@shared/svg/no-tickets.svg?react";
import { AlertBox } from "@shared/ui/AlertBox";
import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import Drawer from "@shared/ui/Drawer";
import { SingleSelectDropdown } from "@shared/ui/Dropdowns";
import Headline, { HEADLINE_SIZES } from "@shared/ui/Headline";
import {
  Input,
  RadioButtonInput,
  SearchInput,
  Textarea,
} from "@shared/ui/Inputs";
import NotFoundComponent from "@shared/ui/NotFound";
import Toast, { TOAST_TYPES } from "@shared/ui/Toast";
import moment from "moment-timezone";
import { Info, Plus } from "phosphor-react";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { toast } from "react-toastify";

import NoPMScheduled from "./_empty";
import PreventiveEvent from "./_event";
import PartsInstancePME from "./assets/PMEParts";
import ProcedureInstancePME from "./assets/PMEProcedures";
import {
  createPreventiveMaintenanceEvent,
  updatePreventiveMaintenanceEvent,
} from "../../services/preventiveMaintenance";
import { DatePickerV2 } from "../form/_datepicker";
import useAuth from "../general/_use-auth";

import {
  ADD_PREVENTIVE_MAITENANCE_EVENT,
  INITIATE_ADD_PREVENTIVE_MAITENANCE_EVENT,
} from "#/src/constants";
import { MachineHistoryLoading } from "#/src/containers/machine/loader/MachineHistoryLoading";
import { registerMixpanelEvent } from "#/src/utils/_mixpanel";
import getEnum from "$/settings/enums";
import PAID_FEATURES from "$/settings/paid-features";
import EmptySpace from "~/components/_emptySpace";
import { useRoleManager } from "~/hooks/_useRoleManager";
import { useGetAppConfigRecoilValue } from "~/utils/appFeatures";
import isPaidFeatureAvailable from "~/utils/isPaidFeatureAvailable";

const PreventiveMaintenance = ({
  events,
  isLoadingEvents,
  machineId = null,
  showSearch = true,
  showCreateCTA = true,
  tileVariant = false,
  refetchEvents = () => {},
  isSharedAssetsPage = false,
}) => {
  const PaidFeatures = getEnum(PAID_FEATURES, "reference");
  const {
    messages: { preventiveMaintenance: PM, common },
  } = useIntl();

  const { user } = useAuth();

  const allowHourlyPMEOption = user?.oem?.allowHourlyPMEOption;

  const frequency = [
    ...(allowHourlyPMEOption
      ? [{ label: PM?.frequency.hour, value: "Hour" }]
      : []),
    { label: PM?.frequency.day, value: "Day" },
    { label: PM?.frequency.week, value: "Week" },
    { label: PM?.frequency.month, value: "Month" },
    { label: PM?.frequency.year, value: "Year" },
  ];

  const initialEventData = {
    title: "",
    eventDate: null,
    frequency: "noRepeat",
    repeatInNumber: "",
    repeatIn: "",
    ticketCreationNumber: "1",
    ticketCreationIn: frequency[0].value,
    description: "",
  };
  const initialEventErrorData = {
    title: "",
    ticketCreationNumber: "",
    repeatInNumber: "",
  };
  const [eventList, setEventList] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const appConfig = useGetAppConfigRecoilValue();
  const [showEditDrawer, setShowEditDrawer] = useState(null);
  const [event, setEvent] = useState(initialEventData);
  const [eventError, setEventError] = useState(initialEventErrorData);

  const { isOemTechnician } = useRoleManager(user?.role);
  const { createEvent } = createPreventiveMaintenanceEvent();
  const { updateEvent } = updatePreventiveMaintenanceEvent();

  const isFeatureAccessible = isPaidFeatureAvailable(
    PaidFeatures.preventiveMaintenance,
    user,
    appConfig,
  );

  const prepareFrequencyOptions = () => {
    return [
      { name: PM?.form.frequencyNoRepeat, value: "noRepeat" },
      { name: PM?.form.frequencyRepeat, value: "repeat" },
    ];
  };

  const prepareInfo = () => {
    let info = "";
    if (event.ticketCreationIn && event.ticketCreationNumber) {
      info = PM?.form.ticketCreationInfo.replace(
        "{{}}",
        ` ${event.ticketCreationNumber} ${
          PM?.frequency[event.ticketCreationIn.toLowerCase()]
        } `,
      );
    } else {
      info = PM?.form.ticketCreationInfo.replace(
        "{{}}",
        `1 ${PM?.frequency.day}`,
      );
    }
    return info;
  };

  const handleNoPMAddEventClick = () => {
    !isSharedAssetsPage && !isOemTechnician && setDrawerOpen(true);
  };

  const inputHasError = () => {
    let err = false;

    Object.keys(eventError).map((ev) => {
      if (eventError[ev] !== "") {
        err = true;
      }
    });

    return err;
  };

  const shouldDisableSubmit = () => {
    let disabled = true;
    if (event.title && event.eventDate?.day) {
      disabled = false;
    }

    if (
      event.frequency === "repeat" &&
      (!event.repeatIn || !+event.repeatInNumber)
    ) {
      disabled = true;
    }

    return disabled;
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
    setEventError(initialEventErrorData);
    setEvent(initialEventData);
  };

  const handleEditDrawerClose = () => {
    setDrawerOpen(false);
    setEventError(initialEventErrorData);
    setEvent(initialEventData);
    setShowEditDrawer(null);
  };

  const checkValidTicketCreationDate = () => {
    const formatedEventDate = moment(
      `${event.eventDate?.year}-${event.eventDate?.month}-${event.eventDate?.day}`,
    );
    let nextTicketDate = formatedEventDate;
    if (event.ticketCreationIn) {
      nextTicketDate = moment(formatedEventDate).subtract(
        event.ticketCreationNumber,
        event.ticketCreationIn,
      );
    }
    const isValid = moment() < nextTicketDate;

    return isValid;
  };

  const handleSubmit = async () => {
    const repetitive = event.frequency === "repeat";
    const repeatEventInDefault = repetitive ? frequency[2].value : undefined;
    const repeatInNumberDefault = repetitive ? 6 : 0;

    const payload = {
      ...event,
      eventDate: new Date(
        event.eventDate?.year,
        event.eventDate?.month - 1,
        event.eventDate?.day,
      )?.toISOString(),
      startDate: new Date().toISOString(),
      timezoneOffset: new Date().getTimezoneOffset(),
      title: event.title.trim(),
      description: event.description.trim(),
      repeatIn: event.repeatIn || repeatEventInDefault,
      repeatInNumber: parseInt(event.repeatInNumber) || repeatInNumberDefault,
      ticketCreationIn: event.ticketCreationIn || frequency[0].value,
      ticketCreationNumber: parseInt(event.ticketCreationNumber) || 1,
      oem: user?.oem?._id,
      machine: machineId || event.machineId,
      procedures:
        event?.procedures?.map((proc) => ({ procedure: proc.procedure._id })) ||
        [],
      inventoryParts: event?.inventoryParts
        ?.filter((ev) => ev.quantity > 0)
        ?.map((ev) => ({
          part: ev.part._id,
          quantity: ev.quantity,
        })),
    };

    if (checkValidTicketCreationDate()) {
      registerMixpanelEvent(ADD_PREVENTIVE_MAITENANCE_EVENT);
      try {
        await createEvent(payload);
        setDrawerOpen(false);
        setEventError(initialEventErrorData);
        setEvent(initialEventData);
        toast(
          <Toast type={TOAST_TYPES.SUCCESS} message={PM?.successCreation} />,
          {
            closeButton: false,
          },
        );
      } catch (exception) {
        toast(
          <Toast
            type={TOAST_TYPES.ERROR}
            message={common?.errors?.somethingWrong}
          />,
          {
            closeButton: false,
          },
        );
      }
    } else {
      toast(
        <Toast
          type={TOAST_TYPES.ERROR}
          message={PM?.error.invalidTicketCreationDate}
        />,
        {
          closeButton: false,
        },
      );
    }
  };

  const handleUpdateEvent = async () => {
    const repetitive = event.frequency === "repeat";
    const repeatEventInDefault = repetitive ? frequency[2].value : undefined;
    const repeatInNumberDefault = repetitive ? 6 : 0;

    const payload = {
      ...event,
      eventDate: new Date(
        event.eventDate?.year,
        event.eventDate?.month - 1,
        event.eventDate?.day,
      )?.toISOString(),
      title: event.title.trim(),
      description: event.description.trim(),
      repeatIn: event.repeatIn || repeatEventInDefault,
      repeatInNumber: parseInt(event.repeatInNumber) || repeatInNumberDefault,
      ticketCreationIn: event.ticketCreationIn || frequency[0].value,
      ticketCreationNumber: parseInt(event.ticketCreationNumber) || 1,
      oem: user?.oem?._id,
      machine: machineId || event.machine,
      procedures:
        event?.procedures?.map((proc) => ({ procedure: proc.procedure._id })) ||
        [],
      inventoryParts: event?.inventoryParts?.map((ev) => ({
        part: ev.part._id,
        quantity: ev.quantity,
      })),
    };

    if (checkValidTicketCreationDate()) {
      if (!inputHasError()) {
        try {
          await updateEvent(payload);

          setShowEditDrawer(null);
          setEvent(initialEventData);
          setEventError(initialEventErrorData);
          toast(
            <Toast
              type={TOAST_TYPES.SUCCESS}
              message={PM?.successModification}
            />,
            {
              closeButton: false,
            },
          );
        } catch (exception) {
          toast(
            <Toast
              type={TOAST_TYPES.ERROR}
              message={common?.errors?.somethingWrong}
            />,
            {
              closeButton: false,
            },
          );
        }
      }
    } else {
      toast(
        <Toast
          type={TOAST_TYPES.ERROR}
          message={PM?.error.invalidTicketCreationDate}
        />,
        {
          closeButton: false,
        },
      );
    }
  };

  const handleSearchEvent = (value) => {
    if (value) {
      const filteredEvents = events?.filter((ev) =>
        ev.title?.toLowerCase()?.includes(value?.toLowerCase()),
      );
      return setEventList(filteredEvents);
    } else {
      return setEventList(events);
    }
  };

  useEffect(() => {
    if (events?.length) {
      setEventList(events);
    }
  }, [events]);

  if (isLoadingEvents) {
    return <MachineHistoryLoading />;
  }

  return (
    <>
      <div className="preventive-maintenance-container">
        {!events?.length ? (
          tileVariant ? (
            <NotFoundComponent
              title={PM["noPreventiveMaintenances"]}
              description={PM["noPreventiveMaintenancesInfo"]}
              showDescription
              showCTA={false}
            />
          ) : (
            <NoPMScheduled
              addEventCallback={handleNoPMAddEventClick}
              isOemTechnician={isOemTechnician}
              isSharedAssetsPage={isSharedAssetsPage}
            />
          )
        ) : (
          <>
            <div className="preventive-maintenance-header">
              <div className="tab-inner-content-header user-accounts-header">
                {!isOemTechnician && !isSharedAssetsPage && showCreateCTA && (
                  <button
                    onClick={() => {
                      registerMixpanelEvent(
                        INITIATE_ADD_PREVENTIVE_MAITENANCE_EVENT,
                      );
                      if (isFeatureAccessible) {
                        setDrawerOpen(true);
                      } else {
                        setShowAlert(true);
                      }
                    }}
                    className="btn-v2 link-btn-v2 btn-icon-left"
                  >
                    <Plus size={14} /> {PM?.createEventButtonText}
                  </button>
                )}

                {showSearch && (
                  <SearchInput
                    placeholder={common?.searchText}
                    onChange={handleSearchEvent}
                  />
                )}
              </div>
            </div>

            <div className="preventive-maintenance-content">
              {eventList?.map((evt, index) => {
                return (
                  <div
                    key={index}
                    className="preventive-maintenance-event-wrapper"
                  >
                    <PreventiveEvent
                      eventId={evt._id}
                      key={evt._id}
                      title={evt.title}
                      startDate={evt.startDate}
                      createdAt={evt.created_at}
                      repeatInNumber={evt.repeatInNumber}
                      repeatIn={evt.repeatIn}
                      eventDate={evt.eventDate}
                      description={evt.description}
                      frequency={evt.frequency}
                      nextCreationDate={evt.nextCreationDate}
                      ticketCreationNumber={evt.ticketCreationNumber}
                      ticketCreationIn={evt.ticketCreationIn}
                      machineId={machineId || evt.machine._id}
                      machineName={evt.machine.name}
                      machineFacilityName={evt.machine.customer.name}
                      machineFacilityId={evt.machine.customer._id}
                      refetchEvents={refetchEvents}
                      onEditButtonClick={(data) => {
                        setEvent(data);
                        setShowEditDrawer(evt._id);
                      }}
                      createdBy={evt.createdBy}
                      tileVariant={tileVariant}
                      procedures={evt?.procedures}
                      inventoryParts={evt?.inventoryParts}
                    />
                    {/* Drawer for update event */}
                    <Drawer
                      title={PM?.editEventTitleText}
                      isOpen={showEditDrawer === evt._id}
                      onClose={handleEditDrawerClose}
                      onCancel={handleEditDrawerClose}
                      onSubmit={handleUpdateEvent}
                      submitButtonDisabled={false}
                      submitButtonText={common.update}
                      cancelButtonDisabled={false}
                      cancelButtonText={common.cancel}
                    >
                      <div className="preventive-maintenance-drawer-content-wrapper update-event-modal-content">
                        <Input
                          label={`${PM?.form.eventName} *`}
                          keyId="pm-event-name"
                          placeholder={PM?.form.eventNamePlaceholder}
                          value={event.title}
                          errorMessage={eventError.title}
                          onChange={(e) => {
                            const eventTitleLength = e.target.value.length;

                            if (eventTitleLength > 75) {
                              return setEventError({
                                title: PM?.eventNameCharacterLimitError,
                              });
                            } else {
                              return setEvent({
                                ...event,
                                title: e.target.value,
                              });
                            }
                          }}
                        />

                        <EmptySpace height="8px" />

                        <DatePickerV2
                          onChange={(e) => {
                            setEvent({
                              ...event,
                              eventDate: e,
                            });
                          }}
                          icon={false}
                          inputPlaceholder={PM?.form.startDatePlaceholder}
                          defaultValue={event.eventDate}
                          label={`${PM?.form.eventDate} *`}
                          dateFromToday
                        />

                        <RadioButtonInput
                          keyId={`update-frequency-select-${evt._id}`}
                          label={PM?.form.frequencyLabel}
                          options={prepareFrequencyOptions()}
                          value={event.frequency}
                          onChange={(e) => {
                            const v = e.target.value;
                            if (v === "repeat") {
                              setEvent({
                                ...event,
                                frequency: v,
                                repeatInNumber: evt.repeatInNumber || 6,
                                repeatIn: evt.repeatIn || frequency[2].value,
                              });
                            } else {
                              setEvent({
                                ...event,
                                frequency: v,
                                repeatInNumber: "",
                                repeatIn: "",
                              });
                              setEventError({
                                ...eventError,
                                repeatInNumber: "",
                              });
                            }
                          }}
                        />

                        <EmptySpace height="16px" />

                        <BodyText
                          size={BODY_TEXT_SIZES.X_SMALL}
                          color={
                            event.frequency !== "repeat"
                              ? "text-disabled"
                              : "text-secondary"
                          }
                          className="mb-sm"
                        >
                          {PM?.form.repeatEventEvery}{" "}
                          {event.repeatIn
                            ? `${event.repeatInNumber} ${
                                PM?.frequency[event.repeatIn.toLowerCase()]
                              }`
                            : ""}
                        </BodyText>

                        <div className="input-group">
                          {/* repeatEventEvery */}
                          <div className="max-w-[35%] mr-lg mb-sm">
                            <Input
                              keyId="update-frequency-number"
                              type="number"
                              value={event.repeatInNumber}
                              placeholder="6"
                              disabled={event.frequency !== "repeat"}
                              onKeyDown={(e) => {
                                if (e.key === ".") {
                                  e.preventDefault();
                                }
                              }}
                              errorMessage={eventError.repeatInNumber}
                              onChange={(e) => {
                                const v = e.target.value;
                                const n = Number(v);

                                if (!v || n < 1) {
                                  setEventError({
                                    ...eventError,
                                    repeatInNumber:
                                      PM?.error.zeroOrEmptyNotAllowed,
                                  });
                                } else {
                                  setEventError({
                                    ...eventError,
                                    repeatInNumber: "",
                                  });
                                }
                                setEvent({
                                  ...event,
                                  repeatInNumber: e.target.value,
                                });
                              }}
                            />
                          </div>

                          <SingleSelectDropdown
                            keyId="edit-repeat-frequency"
                            options={frequency}
                            disabled={event.frequency !== "repeat"}
                            searchable
                            placeholder={frequency[2]?.label}
                            onChange={(selected) =>
                              setEvent({
                                ...event,
                                repeatIn: selected[0]?.value,
                              })
                            }
                            values={
                              event.repeatIn
                                ? [
                                    {
                                      label:
                                        PM?.frequency[
                                          event.repeatIn.toLowerCase()
                                        ],
                                      value: event.repeatIn,
                                    },
                                  ]
                                : []
                            }
                          />
                        </div>

                        <BodyText
                          size={BODY_TEXT_SIZES.X_SMALL}
                          color="text-secondary"
                          className="mb-sm"
                        >
                          {prepareInfo()}
                        </BodyText>

                        <div className="input-group">
                          <div className="max-w-[35%] mr-lg mb-sm">
                            <Input
                              keyId="update-ticket-frequency-number"
                              type="number"
                              value={event.ticketCreationNumber}
                              placeholder="1"
                              onKeyDown={(e) => {
                                if (e.key === ".") {
                                  e.preventDefault();
                                }
                              }}
                              errorMessage={eventError.ticketCreationNumber}
                              onChange={(e) => {
                                const v = e.target.value;
                                const n = Number(v);

                                if (!v || n < 1) {
                                  setEventError({
                                    ...eventError,
                                    ticketCreationNumber:
                                      PM?.error.zeroOrEmptyNotAllowed,
                                  });
                                } else {
                                  setEventError({
                                    ...eventError,
                                    ticketCreationNumber: "",
                                  });
                                }
                                setEvent({
                                  ...event,
                                  ticketCreationNumber: e.target.value,
                                });
                              }}
                            />
                          </div>

                          <SingleSelectDropdown
                            keyId="edit-time-before"
                            options={frequency}
                            searchable
                            placeholder={frequency[0]?.label}
                            onChange={(selected) =>
                              setEvent({
                                ...event,
                                ticketCreationIn: selected[0]?.value,
                              })
                            }
                            values={
                              event.ticketCreationIn
                                ? [
                                    {
                                      label:
                                        PM?.frequency[
                                          event.ticketCreationIn.toLowerCase()
                                        ],
                                      value: event.ticketCreationIn,
                                    },
                                  ]
                                : [
                                    {
                                      label: frequency[0].label,
                                      value: frequency[0].value,
                                    },
                                  ]
                            }
                          />
                        </div>

                        <Textarea
                          label={PM?.form.description}
                          keyId="add-event-description"
                          placeholder={PM?.form.descriptionPlaceholder}
                          value={event.description}
                          onChange={(e) =>
                            setEvent({
                              ...event,
                              description: e.target.value,
                            })
                          }
                        />
                        <ProcedureInstancePME
                          isClosedTicket={false}
                          handleDrawerClose={handleDrawerClose}
                          preventiveMaintenanceId={null}
                          setEventCb={setEvent}
                          event={event}
                        />
                        <PartsInstancePME
                          isClosedTicket={false}
                          handleDrawerClose={handleDrawerClose}
                          preventiveMaintenanceId={null}
                          setEventCb={setEvent}
                          event={event}
                          machineId={machineId || evt?.machine?._id}
                        />
                      </div>
                    </Drawer>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>

      <AlertBox
        acceptButtonText={PM?.noPMAlertOkayButton}
        image={<NoTicketsIcon width="130" height="130" />}
        alertContent={
          <Headline size={HEADLINE_SIZES.X_SMALL}>
            {PM?.noPMAlertTitle}{" "}
            <a
              href={`mailto:${PM?.noPMAlertTitleEmail}`}
              className="text-brand"
            >
              {PM?.noPMAlertTitleEmail}
            </a>
          </Headline>
        }
        onAccept={() => {
          setShowAlert(false);
        }}
        overlay
        isOpen={showAlert}
      />

      <Drawer
        title={PM?.drawerTitle}
        isOpen={drawerOpen}
        onClose={handleDrawerClose}
        onSubmit={handleSubmit}
        submitButtonDisabled={shouldDisableSubmit() || inputHasError()}
        submitButtonText={PM?.createButton}
      >
        <div className="preventive-maintenance-drawer-content-wrapper">
          <div className="w-full bg-brand-lightest p-sm flex rounded-lg gap-sm mt-2xs mb-2xl">
            <Info size={16} className="text-secondary" />
            <BodyText size={BODY_TEXT_SIZES.X_SMALL} className="no-styles">
              {PM?.addEventBannerMessage}
            </BodyText>
          </div>
          <Input
            label={`${PM?.form.eventName} *`}
            keyId="pm-event-name"
            placeholder={PM?.form.eventNamePlaceholder}
            value={event.title}
            errorMessage={eventError.title}
            onChange={(e) => {
              const eventTitleLength = e.target.value.length;

              if (eventTitleLength > 75) {
                return setEventError({
                  title: PM?.eventNameCharacterLimitError,
                });
              } else {
                setEvent({ ...event, title: e.target.value });
                return setEventError({
                  ...eventError,
                  title: "",
                });
              }
            }}
          />
          <EmptySpace height="8px" />
          <DatePickerV2
            onChange={(e) => {
              setEvent({
                ...event,
                eventDate: e,
              });
            }}
            icon={false}
            inputPlaceholder={PM?.form.startDatePlaceholder}
            defaultValue={event.eventDate}
            label={`${PM?.form.eventDate} *`}
            dateFromToday
          />
          <RadioButtonInput
            keyId="frequency-select"
            label={PM?.form.frequencyLabel}
            options={prepareFrequencyOptions()}
            value={event.frequency}
            onChange={(e) => {
              const v = e.target.value;
              if (v === "repeat") {
                setEvent({
                  ...event,
                  frequency: v,
                  repeatInNumber: "6",
                  repeatIn: frequency[2].value,
                });
              } else {
                setEvent({
                  ...event,
                  frequency: v,
                  repeatInNumber: 0,
                  repeatIn: "",
                });

                setEventError({
                  ...eventError,
                  repeatInNumber: "",
                });
              }
            }}
          />
          <EmptySpace height="16px" />
          <BodyText
            size={BODY_TEXT_SIZES.X_SMALL}
            color={
              event.frequency !== "repeat" ? "text-disabled" : "text-secondary"
            }
            className="mb-sm"
          >
            {PM?.form.repeatEventEvery}{" "}
            {event.repeatIn && event.repeatInNumber
              ? `${event.repeatInNumber} ${
                  PM?.frequency[event.repeatIn.toLowerCase()]
                }`
              : ""}
          </BodyText>
          <div className="input-group">
            <div className="max-w-[35%] mr-lg mb-sm">
              <Input
                keyId="frequency-number"
                type="number"
                value={event.repeatInNumber}
                placeholder="6"
                disabled={event.frequency !== "repeat"}
                errorMessage={eventError.repeatInNumber}
                onKeyDown={(e) => {
                  if (e.key === ".") {
                    e.preventDefault();
                  }
                }}
                onChange={(e) => {
                  const v = e.target.value;
                  const n = Number(v);

                  if (!v || n < 1) {
                    setEventError({
                      ...eventError,
                      repeatInNumber: PM?.error.zeroOrEmptyNotAllowed,
                    });
                  } else {
                    setEventError({
                      ...eventError,
                      repeatInNumber: "",
                    });
                  }

                  setEvent({
                    ...event,
                    repeatInNumber: e.target.value,
                  });
                }}
              />
            </div>

            <SingleSelectDropdown
              keyId="add-repeat-frequency"
              options={frequency}
              disabled={event.frequency !== "repeat"}
              searchable
              placeholder={frequency[2]?.label}
              onChange={(selected) => {
                setEvent({
                  ...event,
                  repeatIn: selected[0]?.value,
                });
              }}
              onKeyDown={(e) => {
                if (e.key === ".") {
                  e.preventDefault();
                }
              }}
              values={
                event.repeatIn
                  ? [
                      {
                        label: PM?.frequency[event.repeatIn.toLowerCase()],
                        value: event.repeatIn,
                      },
                    ]
                  : [frequency[2]]
              }
            />
          </div>
          <BodyText
            size={BODY_TEXT_SIZES.X_SMALL}
            color="text-secondary"
            className="mb-sm"
          >
            {prepareInfo()}
          </BodyText>
          <div className="input-group">
            <div className="max-w-[35%] mr-lg mb-sm">
              <Input
                keyId="ticket-creation-frequency-number"
                type="number"
                value={event.ticketCreationNumber}
                placeholder="1"
                errorMessage={eventError.ticketCreationNumber}
                onKeyDown={(e) => {
                  if (e.key === ".") {
                    e.preventDefault();
                  }
                }}
                onChange={(e) => {
                  const v = e.target.value;
                  const n = Number(v);

                  if (!v || n < 1) {
                    setEventError({
                      ...eventError,
                      ticketCreationNumber: PM?.error.zeroOrEmptyNotAllowed,
                    });
                  } else {
                    setEventError({
                      ...eventError,
                      ticketCreationNumber: "",
                    });
                  }

                  setEvent({
                    ...event,
                    ticketCreationNumber: e.target.value,
                  });
                }}
              />
            </div>

            <SingleSelectDropdown
              keyId="add-time-before"
              options={frequency}
              searchable
              placeholder={frequency[0]?.label}
              onChange={(selected) => {
                setEvent({
                  ...event,
                  ticketCreationIn: selected[0]?.value,
                });
              }}
              values={
                event.ticketCreationIn
                  ? [
                      {
                        label: event.ticketCreationIn,
                        value: event.ticketCreationIn,
                      },
                    ]
                  : [
                      {
                        label: frequency[0].value,
                        value: frequency[0].value,
                      },
                    ]
              }
            />
          </div>
          <Textarea
            label={PM?.form.description}
            keyId="add-event-description"
            placeholder={PM?.form.descriptionPlaceholder}
            value={event.description}
            onChange={(e) =>
              setEvent({
                ...event,
                description: e.target.value,
              })
            }
          />
          <ProcedureInstancePME
            isClosedTicket={false}
            handleDrawerClose={handleDrawerClose}
            preventiveMaintenanceId={null}
            setEventCb={setEvent}
            event={event}
          />
          <PartsInstancePME
            isClosedTicket={false}
            handleDrawerClose={handleDrawerClose}
            preventiveMaintenanceId={null}
            setEventCb={setEvent}
            event={event}
            machineId={machineId}
          />
        </div>
      </Drawer>
    </>
  );
};

export default React.memo(PreventiveMaintenance);
