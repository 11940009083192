import DeleteIcon from "@shared/svg/delete-document.svg?react";
import NoTicketsIcon from "@shared/svg/no-tickets.svg?react";
import { AlertBox } from "@shared/ui/AlertBox";
import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import Button, { BUTTON_SIZES, BUTTON_VARIANTS } from "@shared/ui/Button";
import { AssignItemListItem } from "@shared/ui/ListItemCards";
import AppModal from "@shared/ui/Modal";
import Toast, { TOAST_TYPES } from "@shared/ui/Toast";
import { Info, Plus } from "phosphor-react";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { AutoSizer, List as ListRV } from "react-virtualized";
import { Waypoint } from "react-waypoint";

import { NotFoundComponent } from "~/components/_notFoundMessage";
import { useAuth } from "~/components/general";
import { PartRow } from "~/components/inventory/_inventoryPartRow";
import PartPreview from "~/components/inventory/PartPreview";
import { MachineLoaderComponent } from "~/components/skeletonLoaders/_machineLoders";
import {
  ADD_MACHINE_PARTS,
  ITEMS_BEFORE_PAGE,
  ITEMS_PER_PAGE,
} from "~/constants";
import { useRoleManager } from "~/hooks/_useRoleManager";
import { NoUserAssignedIcon } from "~/icons/icons";
import { useAssignAssetParts, useRemoveAssetPart } from "~/services/asset";
import { getAllParts } from "~/services/inventory/_query";
import { capitalizeFirstLetter, getPartThumbnail } from "~/utils";
import { registerMixpanelEvent } from "~/utils/_mixpanel";

const MachineParts = ({
  parts = [],
  isDetached = false,
  showDetachAlert = false,
  partsCustomAdditionalFields = [],
  isSharedAssetsPage = false,
}) => {
  const { removePart: removeAssetPart } = useRemoveAssetPart();

  const { messages } = useIntl();
  const params = useParams();

  const selectedPart = React.useRef(null);
  const removePartRef = React.useRef(null);
  const partTableHeadRef = React.useRef();
  const [searchValue, setSearchValue] = React.useState("");
  const [searchQuery, setSearchQuery] = React.useState("");
  const [partRemoveConfirmation, setPartRemoveConfirmation] = React.useState({
    name: "",
    show: false,
  });
  const [showPartPreview, setShowPartPreview] = useState(false);

  const [showDetachTemplateAlert, setShowDetachTemplateAlert] =
    React.useState(false);

  const {
    parts: allParts,
    fetchingParts,
    handleFetchMore,
    totalCount,
  } = getAllParts({
    limit: ITEMS_PER_PAGE,
    skip: 0,
    searchQuery,
  });

  const handleScrollBottom = (event) => {
    if (
      allParts.length >= ITEMS_PER_PAGE &&
      !fetchingParts &&
      event?.previousPosition !== "above" &&
      totalCount > allParts.length
    ) {
      handleFetchMore({
        limit: ITEMS_PER_PAGE,
        skip: allParts.length,
      });
    }
  };

  const filteredParts = React.useMemo(
    () =>
      (parts || [])?.filter(
        (item) =>
          item?.part?.name.toLowerCase()?.includes(searchValue.toLowerCase()) ||
          item?.part?.articleNumber
            .toLowerCase()
            ?.includes(searchValue.toLowerCase()),
      ),
    [searchValue, parts],
  );

  const tableHeadBottomDistance = React.useMemo(
    () =>
      window.scrollY +
      (partTableHeadRef.current?.getBoundingClientRect()?.bottom || 0),
    [partTableHeadRef.current],
  );

  const handleNameClick = (id) => {
    const partIndex = parts.findIndex(({ part }) => part._id === id);

    selectedPart.current = parts[partIndex].part;
    setShowPartPreview(true);
  };

  const onDeletePartDetachConfirm = () => {
    handleDeleteAccept();
  };

  const handleDeleteClick = (part) => {
    removePartRef.current = part;

    if (showDetachAlert && !isDetached) {
      setShowDetachTemplateAlert(true);
      return;
    }

    setPartRemoveConfirmation({ show: true, name: part.name });
  };

  const handleDeleteCancel = () => {
    removePartRef.current = null;
    setPartRemoveConfirmation({ show: false, name: "" });
  };

  const handleDeleteAccept = async () => {
    try {
      await removeAssetPart({
        assetId: params?.id,
        partId: removePartRef.current.id,
      });
      toast(<Toast message={messages?.machines?.machinePartRemoved} />, {
        closeButton: false,
      });
    } catch (error) {
      toast(
        <Toast
          type={TOAST_TYPES.ERROR}
          message={messages["hierarchy"]["assets"]["partRemoveFailure"]}
        />,
        { closeButton: false },
      );
    } finally {
      removePartRef.current = null;
      setPartRemoveConfirmation({ show: false, name: "" });
    }
  };

  return (
    <>
      {isDetached && showDetachAlert && !isSharedAssetsPage && (
        <div className="machine-template-part-info-container flex items-center p-sm mb-lg text-xs font-medium rounded">
          <Info size={16} className="u-text-color-gray-v2-40 u-margin-r-2" />{" "}
          <BodyText size={BODY_TEXT_SIZES.X_SMALL}>
            {messages["hierarchy"]["assets"]["detachedMessages"]["parts"]}
          </BodyText>
        </div>
      )}
      {parts?.length > 0 && (
        <section>
          <input
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
            placeholder={messages["hierarchy"]["assets"]["searchParts"]}
            className="c-input machine-parts-search"
            value={searchValue}
          />
        </section>
      )}
      <MachinePartsHead
        machineParts={parts || []}
        allParts={allParts}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        onScrollToBottom={handleScrollBottom}
        partsLoading={fetchingParts}
        showDetachAlert={showDetachAlert}
        isDetached={isDetached}
        totalCount={totalCount}
        showDetachTemplateAlert={showDetachTemplateAlert}
        setShowDetachTemplateAlert={setShowDetachTemplateAlert}
        onDeletePartDetachConfirm={onDeletePartDetachConfirm}
        isSharedAssetsPage={isSharedAssetsPage}
      />
      {!parts?.length && <NoMachinePart messages={messages} />}

      {parts?.length > 0 && (
        <section className="inventory-parts__container u-padding-x-0 overflow-x-hidden no-scrollbar">
          <div ref={partTableHeadRef} className="parts-table__head u-flex">
            <div className="parts-table__col u-width-3">
              {messages?.inventory?.labels.name}
            </div>
            <div className="parts-table__col u-width-5 !px-0">
              {messages?.inventory?.labels.description}
            </div>
            <div className="parts-table__col u-width-3 !px-0">
              {messages?.inventory?.labels.articleNumber}
            </div>
            {!isSharedAssetsPage && (
              <div className="parts-table__col u-width-1 u-text-center">
                {messages?.inventory?.action}
              </div>
            )}
          </div>

          {!filteredParts.length && searchValue && (
            <NotFoundComponent
              icon={<NoUserAssignedIcon />}
              title={messages["common"]["noResultFound"]}
            />
          )}

          <div
            style={{
              width: "calc(100% + 24px)",
              height: `calc(100vh - 16px - ${tableHeadBottomDistance}px)`,
            }}
          >
            <AutoSizer>
              {({ width, height }) => (
                <ListRV
                  height={height}
                  width={width}
                  rowCount={filteredParts.length}
                  rowHeight={62}
                  rowRenderer={({ index, key, style }) => {
                    const { part } = filteredParts[index];

                    return (
                      <div key={key} style={style} className="u-padding-r-2">
                        <PartRow
                          key={key}
                          part={part}
                          handlers={{ handleNameClick, handleDeleteClick }}
                          showEdit={false}
                          isSharedAssetsPage={isSharedAssetsPage}
                        />
                      </div>
                    );
                  }}
                />
              )}
            </AutoSizer>
          </div>
        </section>
      )}
      {showPartPreview && (
        <PartPreview
          data={selectedPart.current}
          onClose={() => {
            setShowPartPreview(false);
          }}
          customAdditionalFields={partsCustomAdditionalFields}
          isOpen={showPartPreview}
          showCustomFields={!isSharedAssetsPage}
          isSharedAssetsPage={isSharedAssetsPage}
        />
      )}
      <AlertBox
        isOpen={partRemoveConfirmation.show}
        title={messages["hierarchy"]["assets"][
          "unassignConfirmationTitle"
        ].replace("{{}}", partRemoveConfirmation.name)}
        image={<DeleteIcon width="130" height="130" />}
        acceptButtonText={
          messages?.inventory?.unassignConfirmationAcceptBtnText
        }
        cancelButtonText={
          messages?.inventory?.unassignConfirmationCencalBtnText
        }
        onAccept={handleDeleteAccept}
        onCancel={handleDeleteCancel}
      />
    </>
  );
};

const NoMachinePart = ({ messages }) => {
  return (
    <NotFoundComponent
      icon={<NoUserAssignedIcon />}
      title={messages["hierarchy"]["assets"]["noParts"]}
    />
  );
};

const MachinePartsHead = ({
  machineParts = [],
  allParts = [],
  searchQuery,
  setSearchQuery,
  onScrollToBottom,
  partsLoading,
  showDetachAlert,
  isDetached,
  showDetachTemplateAlert,
  setShowDetachTemplateAlert,
  totalCount,
  onDeletePartDetachConfirm,
  isSharedAssetsPage = false,
}) => {
  const { messages } = useIntl();
  const { addNewParts: addAssetParts } = useAssignAssetParts();
  const params = useParams();

  const [showAddPartModal, setShowAddPartModal] = React.useState(false);
  const [updatingMachineParts, setUpdatingMachineParts] = React.useState(false);
  const [selectedIds, setSelectedIds] = React.useState([]);

  const { user } = useAuth();
  const { isOemTechnician } = useRoleManager(user?.role);

  const availableParts = React.useMemo(() => {
    if (!machineParts.length) return allParts;

    const machinePartsIds = machineParts.map(({ part }) => part._id);

    return allParts?.filter((part) => !machinePartsIds.includes(part._id));
  }, [allParts, machineParts]);

  const onAddParts = () => {
    if (showDetachAlert && !isDetached) {
      setShowDetachTemplateAlert(true);
      return;
    }

    handleAddParts();
  };

  useEffect(() => {
    if (
      !partsLoading &&
      availableParts.length < ITEMS_PER_PAGE &&
      totalCount > allParts.length
    )
      onScrollToBottom();
  }, [availableParts]);

  const handleAddParts = async (data) => {
    registerMixpanelEvent(ADD_MACHINE_PARTS);
    try {
      setUpdatingMachineParts(true);

      await addAssetParts({ assetId: params.id, inventoryParts: selectedIds });

      toast(<Toast message={messages?.machines?.machinePartsAdded} />, {
        closeButton: false,
      });
      setSelectedIds([]);
      setShowAddPartModal(false);
    } catch (error) {
      toast(
        <Toast
          type={TOAST_TYPES.ERROR}
          message={
            error?.message || messages["hierarchy"]["assets"]["partsAddFailure"]
          }
        />,
        { closeButton: false },
      );
    } finally {
      setUpdatingMachineParts(false);
    }
  };

  useEffect(() => {
    showAddPartModal && setSearchQuery("");
  }, [showAddPartModal]);

  return (
    <>
      <section
        className={`flex items-center pb-md border-0 border-b border-solid border-primary ${
          !machineParts.length ? "justify-end" : "justify-between"
        }`}
      >
        {!!machineParts.length && (
          <BodyText
            size={BODY_TEXT_SIZES.X_SMALL}
            color="text-secondary"
          >{`${machineParts.length} ${messages["hierarchy"]["assets"]["totalPartsAdded"]}`}</BodyText>
        )}
        {!isOemTechnician && !isSharedAssetsPage && (
          <Button
            variant={BUTTON_VARIANTS.SECONDARY}
            size={BUTTON_SIZES.SMALL}
            text={messages?.inventory?.addNewPart}
            leadingIcon={<Plus size={16} />}
            onClick={() => setShowAddPartModal(true)}
          />
        )}
      </section>

      <AppModal
        className="w-[75vw]"
        maxWidth="lg:!max-w-[650px]"
        contentClassName="min-h-[60vh]"
        isOpen={showAddPartModal}
        title={messages["hierarchy"]["assets"]["addParts"] || ""}
        updateButtonText={capitalizeFirstLetter(
          messages?.inventory?.addParts || "",
        )}
        handleClose={() => {
          setShowAddPartModal(false);
          setSelectedIds([]);
        }}
        handleSubmit={onAddParts}
        disableUpdate={!selectedIds.length || updatingMachineParts}
        disableCancel={updatingMachineParts}
        content={
          <AddPartComp
            allParts={availableParts}
            selectedParts={selectedIds}
            updateSelectedParts={setSelectedIds}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            onScrollToBottom={onScrollToBottom}
            partsLoading={partsLoading}
          />
        }
      />

      <AlertBox
        title={
          selectedIds.length
            ? messages["hierarchy"]["assets"]["detachAlerts"]["parts"]["title"]
            : messages["hierarchy"]["assets"]["detachAlerts"]["deleteParts"][
                "title"
              ]
        }
        description={
          selectedIds.length
            ? messages["hierarchy"]["assets"]["detachAlerts"]["parts"][
                "message"
              ]
            : messages["hierarchy"]["assets"]["detachAlerts"]["deleteParts"][
                "message"
              ]
        }
        isOpen={showDetachTemplateAlert}
        cancelButtonText={messages?.common.cancel}
        acceptButtonText={
          selectedIds.length
            ? messages?.maintenanceDocuments?.continueButtonText
            : messages?.settings?.companyTabs.statusCustomization
                .confirmButtonText
        }
        image={<NoTicketsIcon width="130" height="130" />}
        onCancel={() => {
          setShowDetachTemplateAlert(false);
          setShowAddPartModal(false);
          setSelectedIds([]);
        }}
        onAccept={() => {
          setShowDetachTemplateAlert(false);
          if (selectedIds.length) handleAddParts();
          else onDeletePartDetachConfirm();
        }}
        overlay={true}
      />
    </>
  );
};

const AddPartComp = ({
  allParts,
  selectedParts,
  updateSelectedParts,
  searchQuery,
  setSearchQuery,
  onScrollToBottom,
  partsLoading,
}) => {
  const { messages } = useIntl();

  const handleRowClick = (event, id) => {
    event.stopPropagation();

    const index = selectedParts.findIndex((item) => item === id);

    if (index >= 0) {
      updateSelectedParts((prev) => {
        const newIds = [...prev];
        newIds.splice(index, 1);

        return newIds;
      });
    } else {
      updateSelectedParts((prev) => [...prev, id]);
    }
  };

  const LoaderComponent = () => (
    <>
      <MachineLoaderComponent total={1} />
    </>
  );

  return (
    <>
      <section>
        <input
          onChange={(e) => {
            setSearchQuery(e.target.value);
          }}
          placeholder={messages["hierarchy"]["assets"]["searchParts"]}
          className="c-input machine-parts-search"
          value={searchQuery}
        />
      </section>

      <section>
        <div className="u-flex parts-table__head justify-between w-full">
          <div className="w-11 py-xs"></div>
          <div className="py-xs flex-1">{messages?.inventory.partName}</div>
          <div className="w-1/3 py-xs">
            {messages?.inventory?.labels.articleNumber}
          </div>
        </div>
        {!allParts?.length && !partsLoading && (
          <NotFoundComponent icon={<NoUserAssignedIcon />} />
        )}

        {allParts.map((part, index) => (
          <React.Fragment key={index + "-part"}>
            <div onClick={(e) => handleRowClick(e, part._id)}>
              <AssignItemListItem
                isChecked={selectedParts.includes(part._id)}
                name={part.name}
                itemId={part.articleNumber}
                thumbnail={getPartThumbnail(part)}
              />
            </div>
            {!partsLoading && index === allParts.length - ITEMS_BEFORE_PAGE ? (
              <Waypoint onEnter={onScrollToBottom} />
            ) : null}
          </React.Fragment>
        ))}
      </section>
      {partsLoading ? <LoaderComponent /> : null}
    </>
  );
};

export default MachineParts;
