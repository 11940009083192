import NotFoundIcon from "@shared/svg/not-found.svg?react";
import BodyText, { BODY_TEXT_SIZES } from "@shared/ui/BodyText";
import Headline, { HEADLINE_SIZES } from "@shared/ui/Headline";
import { useIntl } from "react-intl";

const NotFoundComponent = ({
  title,
  description,
  showTitle = true,
  showDescription = false,
  showCTA = false,
  ctaJSX = null,
}) => {
  const { messages } = useIntl();

  return (
    <div className="flex flex-col space-y-3xl items-center justify-center h-full !mt-5xl">
      <NotFoundIcon />
      <div className="flex flex-col space-y-lg items-center">
        <div className="flex flex-col space-y-sm items-center">
          {showTitle && (
            <Headline size={HEADLINE_SIZES.X_SMALL}>
              {title || messages.common?.notFound}
            </Headline>
          )}
          {showDescription && (
            <BodyText
              className="text-center"
              color="text-secondary"
              size={BODY_TEXT_SIZES.SMALL}
              style={{ maxWidth: "220px" }}
            >
              {description ||
                messages["hierarchy"]["assets"]["noAssetsSubTitle"]}
            </BodyText>
          )}
        </div>
        {showCTA && ctaJSX}
      </div>
    </div>
  );
};

export default NotFoundComponent;
