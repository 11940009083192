import getEnums from "$/settings/enums";
import paidFeatures from "$/settings/paid-features.json";

const paidFeaturesEnum = getEnums(paidFeatures, "reference");

export const PAID_FEATURES_CATEGORIES = {
  WORK_MANAGEMENT: "CMMS",
  ASSETS: "Asset Hub",
  _3D: "3D Stream",
  INDUSTRIAL_AI: "Industrial AI",
};

export const PRODUCT_TYPE_TO_FEATURE_TYPE_MAP = {
  cmms: PAID_FEATURES_CATEGORIES.WORK_MANAGEMENT,
  "asset-hub": PAID_FEATURES_CATEGORIES.ASSETS,
  "3d": PAID_FEATURES_CATEGORIES._3D,
  "industrial-ai": PAID_FEATURES_CATEGORIES.INDUSTRIAL_AI,
  "field-service": PAID_FEATURES_CATEGORIES.WORK_MANAGEMENT,
};

export const FEATURE_TYPE_TO_PRODUCT_TYPE_MAP = {
  [PAID_FEATURES_CATEGORIES.WORK_MANAGEMENT]: "cmms",
  [PAID_FEATURES_CATEGORIES.ASSETS]: "asset-hub",
  [PAID_FEATURES_CATEGORIES._3D]: "3d",
  [PAID_FEATURES_CATEGORIES.INDUSTRIAL_AI]: "industrial-ai",
};

export const getFeatureTypeToProductTypeMap = (
  installedProducts: { type: string; tier: string }[],
) => {
  const map = FEATURE_TYPE_TO_PRODUCT_TYPE_MAP;
  if (installedProducts?.find((product) => product.type === PRODUCT_TYPES.CMMS))
    return map;
  return {
    ...map,
    [PAID_FEATURES_CATEGORIES.WORK_MANAGEMENT]: PRODUCT_TYPES.FIELD_SERVICE,
  };
};

export const LIMIT_TYPES = {
  ASSET: "asset",
  MAX_AI_USERS: "max-ai-users",
  AI_STORAGE: "ai-storage",
  AI_QUERIES: "ai-queries",
  AI_NOTETAKER_RECORDING: "ai-notetaker-recording",
};

export const TIER_TYPES = {
  FREE: "free",
  PRO: "pro",
  ENTERPRISE: "enterprise",
};

export const PRODUCT_TYPES = {
  ASSET_HUB: "asset-hub",
  INDUSTRIAL_AI: "industrial-ai",
  THREE_D: "3d",
  CMMS: "cmms",
  FIELD_SERVICE: "field-service",
};

export const PRODUCT_NAMES = {
  [PRODUCT_TYPES.ASSET_HUB]: "Asset Hub",
  [PRODUCT_TYPES.INDUSTRIAL_AI]: "Industrial AI",
  [PRODUCT_TYPES.THREE_D]: "3D Streams",
  [PRODUCT_TYPES.CMMS]: "CMMS",
  [PRODUCT_TYPES.FIELD_SERVICE]: "Field Service",
};

export const PAID_FEATURES_HIERARCHY = {
  [PRODUCT_TYPES.CMMS]: [
    paidFeaturesEnum.workManagement,
    paidFeaturesEnum.procedures,
    paidFeaturesEnum.preventiveMaintenance,
    paidFeaturesEnum.emails,
    paidFeaturesEnum.scheduler,
    paidFeaturesEnum.calendarSync,
    paidFeaturesEnum.analytics,
    paidFeaturesEnum.activityLogging,
    paidFeaturesEnum.teams,
  ],
  [PRODUCT_TYPES.FIELD_SERVICE]: [
    paidFeaturesEnum.workManagement,
    paidFeaturesEnum.procedures,
    paidFeaturesEnum.preventiveMaintenance,
    paidFeaturesEnum.emails,
    paidFeaturesEnum.scheduler,
    paidFeaturesEnum.calendarSync,
    paidFeaturesEnum.analytics,
    paidFeaturesEnum.activityLogging,
    paidFeaturesEnum.teams,
  ],
  [PRODUCT_TYPES.ASSET_HUB]: [
    paidFeaturesEnum.hierarchy,
    paidFeaturesEnum.customerPortal,
    paidFeaturesEnum.branding,
  ],
  [PRODUCT_TYPES.THREE_D]: [
    paidFeaturesEnum._3DModels,
    paidFeaturesEnum._3DGuides,
  ],
  [PRODUCT_TYPES.INDUSTRIAL_AI]: [
    paidFeaturesEnum.aiAssistants,
    paidFeaturesEnum.aiNotetaker,
    paidFeaturesEnum.aiSearch,
    paidFeaturesEnum.aiSearchSummary,
  ],
};

export const paidFeaturesHierarchy = [
  {
    title: PAID_FEATURES_CATEGORIES.WORK_MANAGEMENT,
    showToggle: true,
    subFeatures: [
      paidFeatures.procedures,
      paidFeatures.preventiveMaintenance,
      paidFeatures.emails,
      paidFeatures.scheduler,
      paidFeatures.calendarSync,
      paidFeatures.analytics,
      paidFeatures.activityLogging,
      paidFeatures.teams,
    ],
  },
  {
    title: PAID_FEATURES_CATEGORIES.ASSETS,
    showToggle: false,
    subFeatures: [
      paidFeatures.hierarchy,
      paidFeatures.customerPortal,
      paidFeatures.branding,
    ],
  },
  {
    title: PAID_FEATURES_CATEGORIES._3D,
    showToggle: false,
    subFeatures: [paidFeatures._3DModels, paidFeatures._3DGuides],
  },
  {
    title: PAID_FEATURES_CATEGORIES.INDUSTRIAL_AI,
    showToggle: false,
    subFeatures: [
      paidFeatures.aiAssistants,
      paidFeatures.aiNotetaker,
      paidFeatures.aiSearch,
      paidFeatures.aiSearchSummary,
    ],
  },
];
