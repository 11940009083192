import * as React from "react";
const SvgHourGlass = (props) => /* @__PURE__ */ React.createElement("svg", {
  width: 20,
  height: 20,
  viewBox: "0 0 20 20",
  fill: "currentColor",
  xmlns: "http://www.w3.org/2000/svg",
  ...props
}, /* @__PURE__ */ React.createElement("path", {
  d: "M1.27083 15.5C1.1327 15.5 1.00022 15.4436 0.902549 15.3431C0.804873 15.2426 0.75 15.1064 0.75 14.9643C0.75 14.8222 0.804873 14.6859 0.902549 14.5855C1.00022 14.485 1.1327 14.4286 1.27083 14.4286H2.3125V13.3571C2.31229 12.4475 2.56228 11.5563 3.03359 10.7865C3.50491 10.0168 4.17832 9.39992 4.97604 9.00714C5.27813 8.85821 5.4375 8.60321 5.4375 8.375V7.625C5.4375 7.39679 5.27708 7.14179 4.97604 6.99286C4.17832 6.60008 3.50491 5.98319 3.03359 5.21345C2.56228 4.44371 2.31229 3.55253 2.3125 2.64286V1.57143H1.27083C1.1327 1.57143 1.00022 1.51499 0.902549 1.41452C0.804873 1.31406 0.75 1.17779 0.75 1.03571C0.75 0.893634 0.804873 0.757373 0.902549 0.656907C1.00022 0.556441 1.1327 0.5 1.27083 0.5H12.7292C12.8673 0.5 12.9998 0.556441 13.0975 0.656907C13.1951 0.757373 13.25 0.893634 13.25 1.03571C13.25 1.17779 13.1951 1.31406 13.0975 1.41452C12.9998 1.51499 12.8673 1.57143 12.7292 1.57143H11.6875V2.64286C11.6877 3.55253 11.4377 4.44371 10.9664 5.21345C10.4951 5.98319 9.82168 6.60008 9.02396 6.99286C8.72187 7.14179 8.5625 7.39679 8.5625 7.625V8.375C8.5625 8.60321 8.72292 8.85821 9.02396 9.00714C9.82168 9.39992 10.4951 10.0168 10.9664 10.7865C11.4377 11.5563 11.6877 12.4475 11.6875 13.3571V14.4286H12.7292C12.8673 14.4286 12.9998 14.485 13.0975 14.5855C13.1951 14.6859 13.25 14.8222 13.25 14.9643C13.25 15.1064 13.1951 15.2426 13.0975 15.3431C12.9998 15.4436 12.8673 15.5 12.7292 15.5H1.27083ZM3.35417 1.57143V2.64286C3.35417 3.21821 3.47917 3.7625 3.70521 4.25H10.2948C10.5198 3.7625 10.6458 3.21821 10.6458 2.64286V1.57143H3.35417ZM6.47917 8.375C6.47917 9.12607 5.98125 9.69929 5.42604 9.97357C4.80551 10.279 4.28168 10.7589 3.91505 11.3576C3.54843 11.9563 3.35398 12.6496 3.35417 13.3571C3.35417 13.3571 4.25625 11.9654 6.47917 11.7714V8.375ZM7.52083 8.375V11.7714C9.74375 11.9654 10.6458 13.3571 10.6458 13.3571C10.646 12.6496 10.4516 11.9563 10.0849 11.3576C9.71832 10.7589 9.19448 10.279 8.57396 9.97357C8.01875 9.69929 7.52083 9.12714 7.52083 8.37607V8.375Z",
  fill: "currentColor",
  strokeWidth: 0.3,
  strokeLinecap: "round",
  strokeLinejoin: "round"
}));
export default SvgHourGlass;
