import NotFoundComponent from "@shared/ui/NotFound";
import React from "react";
import { useIntl } from "react-intl";
import { useRecoilValue } from "recoil";

import { unreadChannelMessagesAtom } from "~/atoms/_chat.jsx";
import { TicketItemOpen } from "~/components/_listItem";
import CreateTicketDrawer from "~/components/tickets/Create";
import {
  INITIATE_ADD_WORK_ORDER,
  MIXPANEL_EVENT_PROPERTIES,
  SUBMIT_NEW_WORK_ORDER,
} from "~/constants";
import { registerMixpanelEvent } from "~/utils/_mixpanel";

const ListView = ({
  tickets = [],
  intl,
  onScrollToBottom,
  totalCount,
  loading,
}) => {
  const { messages } = useIntl();
  const unreadChannelMessages = useRecoilValue(unreadChannelMessagesAtom);

  return (
    <>
      {tickets?.length > 0 ? (
        <div className={"other-tickets-group-wrapper"}>
          <TicketItemOpen
            intl={intl}
            channels={unreadChannelMessages?.channels}
            data={tickets}
            onScrollToBottom={onScrollToBottom}
            totalCount={totalCount}
            loading={loading}
          />
        </div>
      ) : (
        <NotFoundComponent
          title={messages?.tickets?.notFound.title}
          description={messages?.tickets?.notFound?.dsc}
          showDescription
          showCTA={true}
          ctaJSX={
            <CreateTicketDrawer
              onOpenDrawer={() =>
                registerMixpanelEvent(INITIATE_ADD_WORK_ORDER)
              }
              onSubmitSuccess={() =>
                registerMixpanelEvent(SUBMIT_NEW_WORK_ORDER, {
                  workOrderSource:
                    MIXPANEL_EVENT_PROPERTIES.workOrderSource.workOrders,
                })
              }
            />
          }
        />
      )}
    </>
  );
};

export default ListView;
